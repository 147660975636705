export const messages = {
    "INVALID_PASSWORD_OR_EMAIL": "Senha ou email incorreto, confira as informações preenchidas.",
    "INVALID_OR_REGISTERED_EMAIL": "Email inválido ou já cadastrado.",
    "INVALID_CODE": "O código utilizado é inválido.",
    "REQUIRED_DIFFERENT_PASS": "A nova senha precisa ser diferente da atual.",
    "COULD_NOT_VALIDATE_CREDENTIALS": "Não foi possível validar suas credentiais.",
    "REFRESH_TOKEN_NOT_PROVIDED": "O refresh token não foi provido na chamada.",
    "CLIENT_ID_NOT_EXISTS": "O ID do cliente não existe.",
    "TERM_NOT_ACCEPTED": "Os termos de uso não foram aceitos.",
    "USER_NOT_ACTIVE": "O usuário não é ativo no sistema.",
    "TOKEN_NOT_PROVIDED": "O token de acesso não foi provido na chamada.",
    "PASSWORDS_DONT_MATCH": "As senha utilizada precisa ser confirmada corretamente.",
    "UNFORMATTED_EMAIL": "O email utilizado é inválido, confira-o novamente.",
    "UNFORMATTED_PASSWORD": "A senha utilizada é inválida, confira-a novamente.",
    "EXPIRED_CODE": "O código utiliza está expirado.",
    "INVALID_REFRESH_TOKEN": "O refresh token utilizado é inválido.",
    "INACTIVE_USER": "Usuário inativo no sistema.",
    "UNKNOWN_ERROR": "Um erro desconhecido ocorreu.",
    "INVALID_TOKEN": "O access token utilizado é inválido.",
    "NOT_FOUND_OR_EXPIRED_TOKEN": "Token não encontrado ou expirado.",
    "PROFILE_NOT_FOUND": "Perfil não encontrado.",
    "DATABASE_ERROR": "Ocorreu um erro no banco de dados. Tente novamente depois.",
    "UTM_NOT_FOUND": "UTM não encontrada.",
    "MIN_DATE_EXCEEDED": "Data minima excedida.",
    "DATA_NOT_FOUND": "Não encontrado nenhum dado.",
    "INVALID_DATA_FORMAT": "A data utilizada está mal formatada.",
    "USER_NOT_FOUND": "Usuário não encontrado.",
    "USER_ALREADY_REGISTERED": "Alguém já tentou se cadastrar com esse email, se foi você, tente fazer o login."
}