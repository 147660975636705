import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styles';

function Button({ onClick, className, children, colorText, background, disabled }) {
  return (
    <StyledButton disabled={disabled} colorText={colorText} background={background} onClick={onClick} type='button' className={className}>
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  colorText: PropTypes.string,
  background: PropTypes.string,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  onClick: () => {},
  className: 'button-class',
  children: '',
  colorText: '#FFFFFF',
  background: '#1B407A',
  disabled: false
}

export default Button;
