import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: #FFFFFF;
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: calc(100% - 22px);
  height: 170px;
  padding: 25px 33px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${(props) => props.margin};
  box-sizing: border-box;
`;

export const StyledIconContent = styled.div`
  background: ${(props) => props.background};
  width: 32px; 
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: 18px;
`;

export const StyledLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  h3 {
    color: #727272;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 5px;
  }

  p {
    font-size: 18px;
    line-height: 27px;
    color: #262626;
    font-weight: 600;
    width: 100%;
  }
`;
