import React from 'react';
import ToolIcon from 'assets/icons/report/tooltip.svg';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { StyledMainContent } from './styles.js';

function TooltipComponent({ label, position }) {
  return (
    <StyledMainContent>
      <Tooltip title={label} placement={position}>
        <img src={ToolIcon} alt="Ícone de tooltip" />
      </ Tooltip>
    </StyledMainContent>
  );
}

TooltipComponent.propTypes = {
  label: PropTypes.string.isRequired,
  position: PropTypes.string
}

TooltipComponent.defaultProps = {
  label: 'Descrição',
  position: 'top'
}

export default TooltipComponent;
