import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  /* justify-content: center; */
  padding-bottom: 35px;
  background-color: #EDEFF4;

  h2 {
    font-size: 18px;
    font-weight: 500;
    align-self: flex-start;
    line-height: 27px;
    color: #262626;
  }
`;

export const StyledChartsContent = styled.div`
  min-width: 700px;
  width: 100%;
`;

export const StyledButton = styled.button`
  background-color: #E7750A;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
`;

export const StyledHeader = styled.div`
  background-color: #EDEFF4;
  padding: 10px 0;
  width: 100%;
  z-index: 3;
  position: sticky;
  top: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledSideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;

  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #667A8C;
  }
`;

export const StyledBarChart = styled.div`
  padding: 25px 24px;
  width: 100%;
  background-color: white;
  height: 400px;
  margin-right: 11px;
  box-sizing: border-box;

  h2 {
    margin-bottom: 32px;
  }
`;

export const StyledLineChart = styled.div`
  margin-left: 11px;
  height: 400px;
  padding: 25px 24px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    width: 200px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #2A2A2A;
    margin-bottom: 20px;
  }
`;

export const StyledLine = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 0;
  border-radius: 5px;
`;

export const StyledBar = styled.div`
  width: 100%;
  min-width: 100%;
  height: 35px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;

  .css-1n7gpw1-MuiSkeleton-root {
    height: 100%;
    width: 100%;
  }

  p {
    margin-left: 7px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #667A8C;
    min-width: 75px;
  }
`;

export const StyledWidth = styled.div`
  width: calc(${(props) => props.width} - 75px);
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: 0px 5px 5px 0px;
`;

export const StyledLegend = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 10px;
  font-size: 12px;
  color: #727272;
  text-align: center;
`;

export const StyledLegendColor = styled.div`
  min-width: 9px;
  min-height: 9px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border-radius: 50%;
`;

export const StyledIconContent = styled.div`
  background-color: rgb(27, 64, 122, 0.3);
  min-width: 40px; 
  min-height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 18px;
`;

export const StyledText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  border-bottom: 2px solid #DBDDE6;

  span {
    margin: 0 4px;
  }
`;

export const StyledTextSkeleton = styled.h3`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .css-1n7gpw1-MuiSkeleton-root {
    width: 40px;
  }
`;

export const StyledSubtitle = styled.h2`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #667A8C;
  margin-top: -12px;
  background-color: #FFF;
  padding-right: 10px;
`;

export const StyledScoring = styled.div`
  width: 100%;
  height: 170px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  padding: 16px 24px;
  background: #FFFFFF;
  box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin: 11px;

  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #667A8C;
    margin-top: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    span {
      color: #2A2A2A;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const StyledComparePast = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 50px;

  h4 {
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    color: #667A8C;
  }
`;