import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  StyledWrapper
} from './styles';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

function TableComponent({
  columns,
  rows,
  count,
  rowsPerPage,
  page,
  setRowsPerPage,
  setPage,
  isLoading
}) {

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledWrapper>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.email||row.cpf}>
                      {columns.map((column) => {
                        let value = row[column.id];
                        let classname = ''
                        if (!value) {
                            value = row[column.secID];
                        }
                        if (column.id === 'score_desc') {
                            classname = `color-${value}`
                        }

                        if (column?.mapping) {
                            value = column.mapping[value]
                        }
                        return (
                          <TableCell className={classname} key={value} align={column.align}>
                            {column.format
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                Array.from({ length: rowsPerPage }, (_, k) => (
                  <TableRow key={k} hover role="checkbox" tabIndex={-1}>
                    {columns.map((elem, i) => {
                      return (
                        <TableCell key={i}>
                          <Skeleton />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página:"
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
          }
        />
      </Paper>
    </StyledWrapper>
  );
}

TableComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    secID: PropTypes.string,
    label: PropTypes.string.isRequired,
    minWidth: PropTypes.number.isRequired,
    format: PropTypes.func
  })),
  rows: PropTypes.arrayOf(PropTypes.any).isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
}

TableComponent.defaultProps = {
  columns: {
    secID: '',
    format: () => {}
  },
  rows: [],
  isLoading: true
}

export default TableComponent;
