import { config, dashApi } from 'services/api';

export async function getUtmCPLQ(start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont) {
  return dashApi.get('utm/cplq/', config({ start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont }));
}

export async function getUtmRevenue(start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont) {
    return dashApi.get('utm/revenue/', config({ start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont }));
}

export async function getRevenuebyUtm(start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont, page, size) {
    return dashApi.get('utm/revenue-by-utm/', config({ start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont, page, size }));
  }