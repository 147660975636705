import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getUtmCPLQ, getUtmRevenue } from "services/utm";
import { getUtms } from "services/dashboard";
import FilterIcon from 'assets/icons/report/filter.svg';
import PercentageIcon from 'assets/icons/revenue/percentage.svg';
import CashIcon from 'assets/icons/revenue/cash.svg';
import MoneyIcon from 'assets/icons/revenue/money.svg';
import NotFoundComponent from 'pages/Reports/Contact/NotFoundChart';
import LineChartComp from 'components/LineChart';
import Skeleton from '@mui/material/Skeleton';
import Filter from 'components/Filter';
import { parserData } from './parserData';
import ChartIcon from 'assets/icons/menu/chart.svg';
import Card from 'pages/Reports/Contact/Card';
import CompareRevenue from './CompareRevenue';
import TableRevenue from './TableRevenue';
import {
  StyledChartsContent,
  StyledMainContent,
  StyledButton,
  StyledHeader,
  StyledSideContainer,
  StyledBarChart,
  StyledBar,
  StyledWidth,
  StyledLegend,
  StyledLegendItem,
  StyledLegendColor,
  StyledLineChart,
  StyledLine,
  StyledScoring,
  StyledIconContent,
  StyledText,
  StyledSubtitle,
  StyledComparePast,
  StyledTextSkeleton
} from './styles';
import Menu from "../Contact/Menu";
import { useOutsideClick } from 'utils/useOutsideClick';
import { StyledFlex } from 'utils/styles';

const colors = {
  sAlto: '#22943E',
  alto: '#8CC14C',
  medio: '#E7750A',
  baixo: '#CE152F',
  sc: '#C8C8C8'
}

function UTM() {
  const currentDate = new Date();
  const [maxValue, setMaxValue] = useState(0);
  const [filtering, setFiltering] = useState(false);
  const [selectionDate, setSelectionDate] = useState(
    {
      startDate: new Date(currentDate.setDate(currentDate.getDate() - 10)),
      endDate: new Date(),
      key: 'selection',
    }
  );
  const [utmFiltered, setUtmFiltered] = useState({});
  const [revenue, setRevenue] = useState();
  const [cplq, setCplq] = useState({});
  const [utms, setUtms] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [type, setType] = useState({
    text: 'Gráfico', image: ChartIcon
  });

  // Loadings
  const [loadingCplq, setLoadingCplq] = useState(true);
  const [loadingRevenue, setLoadingRevenue] = useState(true);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const isSameDate = () => {
    return formatDate(selectionDate?.startDate) === formatDate(selectionDate?.endDate);
  }

  useEffect(() => {
    if (!isSameDate()) {
      getUtm();
    }
  }, [selectionDate]);


  const getUtm = async () => {
    try {
        const response = await getUtms(formatDate(selectionDate?.startDate), formatDate(selectionDate?.endDate));
        setUtms(response?.data);
    } catch {
        setUtms({});
    }
  }

  const handleFilter = () => {
    setFiltering(!filtering);
    setRevenue([]);
    setCplq({});

    getCPLQ();
    getRevenue()
  }

  useEffect(() => {
      getCPLQ();
  }, []);

  useEffect(() => {
      getRevenue();
  }, []);

  const getCPLQ = async () => {
    try {
      setLoadingCplq(true);
        const response = await getUtmCPLQ(
          formatDate(selectionDate?.startDate),
          formatDate(selectionDate?.endDate),
          utmFiltered.utm_source,
          utmFiltered.utm_medium,
          utmFiltered.utm_campaign,
          utmFiltered.utm_term,
          utmFiltered.utm_content,
        );
        setCplq(response?.data);
    } catch {
        setCplq([]);
    }
    setLoadingCplq(false)
  }

  const getRevenue = async () => {
    try {
      setLoadingRevenue(true);
      const response = await getUtmRevenue(
        formatDate(selectionDate?.startDate),
        formatDate(selectionDate?.endDate),
        utmFiltered.utm_source,
        utmFiltered.utm_medium,
        utmFiltered.utm_campaign,
        utmFiltered.utm_term,
        utmFiltered.utm_content,
      );
      setRevenue(response?.data);
    } catch {
      setRevenue([]);
    }
    setLoadingRevenue(false);
  }

  const handleSelectFilter = (params, value) => {
    const oldValue = {
      ...utmFiltered
    };
    const elem = value.map((elem) => {
      return elem;
    });

    oldValue[params] = elem;

    setUtmFiltered({ ...oldValue })
  }

  const handleClickOutside = () => {
    setOpenFilter(false);
  };

  useEffect(() => {
    if (revenue?.revenue_scoring?.length > 0) {
      const scores = revenue?.revenue_scoring.map(object => {
        return object.value;
      });
      setMaxValue(Math.max(...scores))
    }
  }, [revenue]);

  const RenderVariationChart = () => {
    const found = revenue?.results?.findIndex(element => element?.sum_alto > 0 || element?.sum_super_alto  > 0);

    return found !== -1 ? (
      <LineChartComp data={parserData(revenue?.results)} />
    ) : (
      <NotFoundComponent />
    )
  }

  const ref = useOutsideClick(handleClickOutside);

  const getSumValue = () => {
    let sum = 0;
    revenue?.revenue_scoring?.map((i) => {
      if (i.score_desc === 'sAlto' || i.score_desc === 'alto') {
        sum += i.value
      }
    })

    return sum;
  }

  const getPercentage = (alto = 0, sAlto = 0, total = 0) => {
    return loadingRevenue ? <p><Skeleton /></p> : <p>R$ {(alto + sAlto)?.toLocaleString('pt-BR')} ({((((alto + sAlto) * 100) / total) || 0).toFixed(1)}%)</p>
  }

  const calculateTicket = (sumQL, quantAlto, quantsAlto) => {
    const ticket = +((sumQL)/(quantAlto + quantsAlto)).toFixed(2)
    return ticket || 0
  }

  return (
    <StyledMainContent>
      <StyledChartsContent>
        <StyledHeader>
          <Menu handleChange={setType} type={type} />
          <StyledButton type="button" ref={ref} onClick={() => setOpenFilter(!openFilter)}>
            <img src={FilterIcon} />
            <p>Filtrar</p>
          </StyledButton>
        </StyledHeader>
        {type.text === 'Gráfico' && <StyledFlex flex="row">
          <StyledSideContainer>
            <StyledFlex flex="row">
              <Card
                label="Receita Total Gerada"
                value={`R$ ${revenue?.sum_total?.toLocaleString('pt-BR') || '0,00'}`}
                icon={MoneyIcon}
                background={'rgb(34, 148, 62, 0.3)'}
                margin="11px 11px 11px 0"
                isLoading={loadingRevenue} />
              <Card
                label="Custo Médio por Lead Qualificado"
                value={`R$ ${cplq[0]?.cplq?.toLocaleString('pt-BR') || '0,00'}`}
                icon={CashIcon}
                background={'rgb(34, 148, 62, 0.3)'}
                margin="11px 11px 11px 0"
                isLoading={loadingRevenue} />
            </StyledFlex>
            <StyledBarChart>
              <h2>Distribuição de receita por score</h2>
              {loadingRevenue ? (
                Array.from({ length: 5 }, (_, k) => (
                  <StyledBar key={k}>
                    <Skeleton />
                  </StyledBar>
                ))
              ) : (
                revenue?.revenue_scoring?.map((i) => (
                  <StyledBar key={i.score_desc}>
                    <StyledWidth width={`${(100 * i["value"]) / maxValue}%`} color={colors[i["score_desc"]]} />
                    <p>R$ {i["value"]?.toLocaleString('pt-BR')}</p>
                  </StyledBar>
                ))
              )}
              <StyledLegend>
                <StyledLegendItem>
                  <StyledLegendColor color="#22943E" />
                  Super Alto
                </StyledLegendItem>
                <StyledLegendItem>
                  <StyledLegendColor color="#8CC14C" />
                  Alto
                </StyledLegendItem>
                <StyledLegendItem>
                  <StyledLegendColor color="#E7750A" />
                  Médio
                </StyledLegendItem>
                <StyledLegendItem>
                  <StyledLegendColor color="#CA0009" />
                  Baixo
                </StyledLegendItem>
                <StyledLegendItem>
                  <StyledLegendColor color="#C8C8C8" />
                  Sem classificação
                </StyledLegendItem>
              </StyledLegend>
            </StyledBarChart>
          </StyledSideContainer>
          <StyledSideContainer>
            <StyledScoring>
              <StyledIconContent>
                <img src={PercentageIcon} />
              </StyledIconContent>
              <StyledFlex flex="column">
                <StyledText>
                  <h2>Sobre os scores Alto e Super Alto </h2>
                  <StyledTextSkeleton>
                    correspondem a <span>{loadingRevenue ? <Skeleton /> : `R$${getSumValue()?.toLocaleString('pt-BR')}`}</span>, o que equivale a <span>{loadingRevenue ? <Skeleton /> : `${((getSumValue() * 100) / revenue?.sum_total).toFixed(1)}%`}</span> do total da receita
                  </StyledTextSkeleton>
                </StyledText>
                <StyledSubtitle>Mesmo período</StyledSubtitle>
                <StyledFlex flex="row" justify="flex-start">
                  <StyledComparePast>
                    <h4>no mês anterior</h4>
                    {getPercentage(revenue?.sum_alto_last_month, revenue?.sum_super_alto_last_month, revenue?.sum_total_last_month)}
                  </StyledComparePast>
                  <StyledComparePast>
                    <h4>no ano anterior</h4>
                    {getPercentage(revenue?.sum_alto_last_year, revenue?.sum_super_alto_last_year, revenue?.sum_total_last_year)}
                  </StyledComparePast>
                </StyledFlex>
              </StyledFlex>
            </StyledScoring>
            <StyledLineChart>
              <h2>Ticket Médio dos Scores Alto e Super Alto</h2>
              <h3>{loadingRevenue ? <Skeleton /> : `R$ ${calculateTicket(getSumValue(), revenue?.quant_alto, revenue?.quant_super_alto).toLocaleString('pt-BR')}`}</h3>
              <StyledLine>
                {loadingRevenue ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={250}
                    />
                ) : (
                  RenderVariationChart()
                )}
              </StyledLine>
            </StyledLineChart>
          </StyledSideContainer>
        </StyledFlex>}
        {type.text === 'Comparativo' && <CompareRevenue filtering={filtering} utmFiltered={{...utmFiltered}} selectionDate={{...selectionDate}} />}
        {type.text === 'Tabela' && <TableRevenue filtering={filtering} utmFiltered={{...utmFiltered}} selectionDate={{...selectionDate}} />}
      </StyledChartsContent>
      <Filter
        visible={openFilter}
        setVisible={setOpenFilter}
        utms={utms}
        handleSelectFilter={handleSelectFilter}
        onClick={() => handleFilter()}
        selectionDate={selectionDate}
        setSelectionDate={setSelectionDate} />
    </StyledMainContent>
  );
}

export default UTM;
