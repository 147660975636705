import styled from 'styled-components';

export const StyledList = styled.ul`
  width: 100%;
  list-style-type: none;
`;

export const StyledDragableContainer = styled.div`
  width: 100%;
`;

// CARD
export const StyledCardContent = styled.div`
  background: #FFFFFF;
  width: 99%;
  margin: 10px auto;
  padding: 16px 32px;
  box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
`;

export const StyledUTMList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const StyledUTM = styled.div`
  background-color: #EDEFF4;
  color: #667A8C;
  border-radius: 5px;
  padding: 3px 5px;
  margin-right: 5px;
  font-size: 12px;
  white-space: nowrap; 
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCard = styled.div`
  border-radius: 4px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
`;

export const StyledLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &.full-width {
    width: 100%;
  }

  h3 {
    color: #667A8C;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  h4 {
    color: #667A8C;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
  }

  span {
    font-size: 16px;
    line-height: 27px;
    color: #2A2A2A;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    line-height: 27px;
    color: #262626;
  }
`;

export const StyledLineChart = styled.div`
  width: 40%;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 0;
  border-radius: 5px;
  margin-left: 30px;

  h3 { 
    color: #667A8C;
    font-weight: 500;
    font-size: 14px;
    width: max-content;
    margin-bottom: 5px;
    align-self: flex-start;
  }

  h2 {
    margin-bottom: 20px;
  }
`;

export const StyledCardPagination = styled.div`
  width: 100%;
`;

export const StyledLoadingContainer = styled.div`
  height: calc(100vh - 170px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonPagination = styled.button`
  width: 100%;
  background-color: #EDEFF4;
  color: #1B407A;
  padding: 25px 0;
  height: 70px;
  cursor: pointer;
  text-align: center;
  border: none;

  :disabled {
    color: #BBC6D7;
    cursor: default;
  }
`;

export const StyledPaginationText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
`;


export const StyledBarChart = styled.div`
  padding: 15px 0px;
  width: 270px;
  background-color: white;
  height: 180px;
  box-sizing: border-box;
`;

export const StyledSubtitleChart = styled.h3`
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #667A8C;
`;

export const StyledBar = styled.div`
  width: 100%;
  min-width: 100%;
  height: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;

  .css-1n7gpw1-MuiSkeleton-root {
    height: 100%;
    width: 100%;
  }

  p {
    margin-left: 5px;
    font-size: 10px;
    line-height: 19px;
    text-align: center;
    color: #667A8C;
    width: 60px;
  }
`;

export const StyledWidth = styled.div`
  width: calc(${(props) => props.width});
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: 0px 5px 5px 0px;
`;