import { config, googleApi } from 'services/api';

export async function postGoogle(body) {
  return await googleApi.post('google/register/', body, config());
}

export async function getGoogleAccount() {
  return await googleApi.get('google/accounts/', config());
}

export async function putGoogleAccount(body) {
  return await googleApi.put('google/account/', body, config());
}

export async function getGoogleExpired() {
    return await googleApi.get('google/expired/', config());
}
