import styled from 'styled-components';

export const StyledMainContent = styled.div`
  width: 100%;
`;

export const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #00213F;
`;

export const StyledInput = styled.input`
  border: 1px solid #DBDDE6;
  width: 100%;
  font-size: 14px;
  padding: 11px 16px;
  outline: none;
  border-radius: 4px;
`;

export const StyledInputContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 12px;
  position: relative;
`;

export const StyledButtonEye = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
  border: none;
  cursor: pointer;

  img {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
`;
