import React from "react";
import constructionIcon from 'assets/icons/construction.svg';
import { StyledMainContent, StyledText } from './styles';

const Personas = () => {
  return (
    <StyledMainContent>
      <img className="construction-img" src={constructionIcon} alt="ícone de tela em construção" />
      <StyledText>
        <h2>Área em desenvolvimento</h2>
        <p className="construction-text">No momento estamos desenvolvendo esta área. Em breve ela estará disponível.</p>
      </StyledText>
    </StyledMainContent>
  );
};

export default Personas;
