import styled from 'styled-components';

export const StyledMainContent = styled.div`
  width: 100%;
  border-bottom: 1px solid #DBDDE6;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  position: sticky;
  min-height: 70px;
  top: 0px;
  background-color: #EDEFF4;
  z-index: 2;
`;

export const StyledItem = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8295B6;
  border-bottom: ${(props) => props.isActive ? '3px solid #1B407A' : 'none'};
  padding: 7px 12px;
  cursor: pointer;
`;
