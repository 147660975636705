import React, { useState } from 'react';
import {
  StyledMainContent,
  StyledItem
} from './styles';
import PropTypes from 'prop-types';

function Navbar({ options, onClick, firstItem }) {
  const [active, setActive] = useState(firstItem);

  const handleClick = (id) => {
    setActive(id);
    onClick(id);
  }

  return (
    <StyledMainContent>
      {options.map((item) => (
        <StyledItem onClick={() => handleClick(item.id)} key={item.id} isActive={item.id === active}>
          {item.label}
        </StyledItem>
      ))}
    </StyledMainContent>
  );
}

Navbar.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  onClick: PropTypes.func,
  firstItem: PropTypes.string
}

Navbar.defaultProps = {
  options: [{
    id: '',
    label: ''
  }],
  onClick: () => {},
  firstItem: ''
}

export default Navbar;
