import { config, faceApi } from 'services/api';

export async function postFacebook(body) {
  return await faceApi.post('facebook/register/', body, config());
}

export async function getFacebookAccount() {
  return await faceApi.get('facebook/accounts/', config());
}

export async function putFacebookAccount(body) {
  return await faceApi.put('facebook/account/', body, config());
}

export async function getFacebookExpired() {
    return await faceApi.get('facebook/expired/', config());
}
