import styled from 'styled-components';

export const StyledMainContent = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 1px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 30px;
  margin: 10px;
  color: #667A8C;
  height: 270px;
  max-width: 500px;
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  h2 {
    color: #00213F;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 12px 0;
  }

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .kep-login-facebook {
    background-color: #1B407A;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    text-transform: none;
    height: 41px;
    margin-top: 15px;
    align-self: flex-end;
    font-family: 'Poppins', sans-serif;
  }
`;

export const StyledButton = styled.button`
  width: 94px;
  height: 41px;
  background: #1B407A;
  border-radius: 5px;
  color: white;
  border: none;
  margin-top: 12px;
  align-self: flex-end;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const StyledImg = styled.img`

`;