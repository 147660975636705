import React, { useEffect, createContext, forwardRef, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function AutocompleteComponent({ options, isMulti, onChange, label, isDisabled }) {
  const [value, setValue] = useState([]);

  const handleChange = (event, values) => {
    if (values) {
      setValue(values);
      onChange(values);
    }
  };

  const newTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#1B407A"
            },
            color: "#667A8C"
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={newTheme}>
      <Autocomplete
        id="virtualize-demo"
        multiple={isMulti}
        value={value}
        disabled={isDisabled}
        onChange={handleChange}
        size="medium"
        limitTags={2}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={options.filter((e) => e !== null).sort((a, b) => a?.toUpperCase().localeCompare(b?.toUpperCase()) || [])}
        groupBy={(option) => option[0]?.toUpperCase()}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, option) => [props, option]}
        renderGroup={(params) => params}
      />
    </ThemeProvider>
  );
}

AutocompleteComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

AutocompleteComponent.defaultProps = {
  options: [{
    value: '',
    label: '',
    color: 'E6E6E6',
    isFixed: false,
    isDisabled: false
  }],
  isMulti: false,
  label: '',
  onChange: () => {},
  isDisabled: PropTypes.bool
}
