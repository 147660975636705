import React, { useState } from 'react';
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import PropTypes from 'prop-types';
import {
  StyledMainContent
} from './styles';

export default function DateRangePicker({ selectionDate, onChange }) {
  const [state, setState] = useState([
    {
      startDate: selectionDate?.startDate,
      endDate: selectionDate?.endDate,
      key: 'selection',
    }
  ]);

  const handleChange = (item) => {
    setState([item]);
    onChange(item);
  }

  return (
    <StyledMainContent>
      <DateRange
        locale={locales['pt']}
        showDateDisplay={false}
        rangeColors={['#BBC6D7']}
        onChange={item => handleChange(item.selection)}
        ranges={state}
        color='#2A2A2A'
      />
    </StyledMainContent>
  );
}

DateRangePicker.propTypes = {
  selectionDate: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    key: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired
}
