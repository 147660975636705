import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StyledFlex } from "utils/styles";
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  StyledDragableContainer,
  StyledCardContent,
  StyledUTMList,
  StyledUTM,
  StyledCard,
  StyledLabel,
  StyledLineChart,
  StyledButtonPagination,
  StyledCardPagination,
  StyledLoadingContainer,
  StyledPaginationText,
  StyledBarChart,
  StyledWidth,
  StyledBar,
  StyledSubtitleChart
} from './styles';
import LineChartComp from 'components/LineChart';
import LoadingRadius from "components/LoadingRadius";
import ArrowDownIcon from 'assets/icons/menu/arrow.svg';
import NotFoundComponent from "pages/Reports/Contact/CompareUTM/NotFoundResults";
import { getRevenuebyUtm } from 'services/utm';
import { parseData } from "pages/Reports/Contact/parserData";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const colors = {
  "SUPER ALTO": '#22943E',
  "ALTO": '#8CC14C',
  "MEDIO": '#E7750A',
  "BAIXO": '#CE152F',
  "SC": '#C8C8C8'
};

function CompareRevenue({ filtering, selectionDate, utmFiltered }) {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [items, setItems] = useState([]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const getData = async (oldItems = items, newPage = page) => {
    let newItem = [];
    try {
      setIsLoading(true);
      const response = await getRevenuebyUtm(
        formatDate(selectionDate?.startDate),
        formatDate(selectionDate?.endDate),
        utmFiltered.utm_source,
        utmFiltered.utm_medium,
        utmFiltered.utm_campaign,
        utmFiltered.utm_term,
        utmFiltered.utm_content,
        newPage,
        3
      )
      newItem = response?.data?.items
      if (!response?.data) {
        newItem = []
      }
      setItems([...oldItems, ...newItem]);
      setTotalPage(response?.data?.pages);
      setIsLoading(false);
    } catch {
      setItems([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    setTotalPage(1);
    setItems([]);
    getData([], 1);
  }, [filtering]);

  useEffect(() => {
    if (page !== 1) {
      getData();
    }
  }, [page]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems)
  }

  const getMaxValue = (arr) => {
    let max = arr[0].value;

    for(let i = 1; i < arr.length - 1; i++) {
      if (arr[i].value > max) {
        max = arr[i].value;
      }
    }
    return max;
  }

  const getSumProperty = (scores, property) => {
    let sum = 0;
    scores?.map((i) => {
      if (i.score_desc === 'SUPER ALTO' || i.score_desc === 'ALTO') {
        sum += i[property]
      }
    })

    return sum;
  }

  const calculateTicket = (sumQL, sumHighScores) => {
    const ticket = +((sumQL)/(sumHighScores)).toFixed(2)
    return ticket || 0
  }

  return (
    isLoading && items.length === 0 ? (
      <StyledLoadingContainer>
        <LoadingRadius height="250px" />
      </StyledLoadingContainer>
    ) : (
      <StyledDragableContainer>
        {items.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <StyledCardPagination>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {items.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <StyledCardContent
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <h2>{item?.utms?.campaign}</h2>
                            <StyledUTMList>
                              {item?.utms?.campaign && <StyledUTM title={item?.utms?.campaign}>Campaign: {item?.utms?.campaign}</StyledUTM>}
                              {item?.utms?.source && <StyledUTM title={item?.utms?.source}>Source: {item?.utms?.source}</StyledUTM>}
                              {item?.utms?.content && <StyledUTM title={item?.utms?.content}>Content: {item?.utms?.content}</StyledUTM>}
                              {item?.utms?.term && <StyledUTM title={item?.utms?.term}>Term: {item?.utms?.term}</StyledUTM>}
                              {item?.utms?.medium && <StyledUTM title={item?.utms?.medium}>Medium: {item?.utms?.medium}</StyledUTM>}
                            </StyledUTMList>
                            <StyledFlex flex="column">
                              <StyledFlex flex="row" align="flex-start" justify="space-between">
                                <StyledCard>
                                  <StyledLabel className="full-width">
                                    <h3>Receita gerada</h3>
                                    <p>R$ {item?.total?.toLocaleString('pt-BR')}</p>
                                  </StyledLabel>
                                  <StyledLabel className="full-width">
                                    <h3>Custo por lead qualificado</h3>
                                    <p>R$ {Number(item?.cplq?.toFixed(2))?.toLocaleString('pt-BR')}</p>
                                  </StyledLabel>
                                </StyledCard>
                                <StyledBarChart>
                                  <StyledSubtitleChart>Distribuição dos scores</StyledSubtitleChart>
                                  {item?.scores?.map((i) => (
                                    <StyledBar key={i.score_desc}>
                                      <StyledWidth width={`${(100 * i["value"]) / getMaxValue(item?.scores)}%`} color={colors[i["score_desc"]]} />
                                      <p>R$ {Number(i["value"]?.toFixed(0))?.toLocaleString('pt-BR')}</p>
                                    </StyledBar>
                                  ))}
                                </StyledBarChart>
                                <StyledLineChart>
                                  {item?.data?.length > 0 ? (
                                    <>
                                      <h3 className='overview'>Ticket Médio dos Scores Alto e Super Alto</h3>
                                      <h2>{`R$ ${calculateTicket(getSumProperty(item?.scores, "value"), getSumProperty(item?.scores, "sum_scoreds")).toLocaleString('pt-BR')}`}</h2>
                                      <LineChartComp data={parseData(item?.data)} />
                                    </>
                                  ) : (
                                    <LoadingRadius height="250px" />
                                  )}
                                </StyledLineChart>
                              </StyledFlex>
                            </StyledFlex>
                          </StyledCardContent>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {page !== totalPage &&
                <StyledButtonPagination disabled={isLoading} type='button' onClick={() => setPage(page + 1)}>
                  {isLoading ? (
                    <p>Carregando...</p>
                  ) : (
                    <StyledPaginationText>
                      <p>Carregar mais UTMs</p>
                      <img src={ArrowDownIcon} alt="Icone para ver mais" />
                    </StyledPaginationText>
                  )}
                </StyledButtonPagination>}
            </StyledCardPagination>
          </DragDropContext>
        ) : (
          <NotFoundComponent />
        )}
      </StyledDragableContainer>
    )
  );
}

CompareRevenue.propTypes = {
  filtering: PropTypes.bool,
  selectionDate: PropTypes.shape({
    key: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }),
  utmFiltered: PropTypes.shape({
    utm_source: PropTypes.string,
    utm_medium: PropTypes.string,
    utm_campaign: PropTypes.string,
    utm_term: PropTypes.string,
    utm_content: PropTypes.string,
  })
}

CompareRevenue.defaultProps = {
  filtering: false,
  selectionDate: {
    startDate: new Date(moment().subtract(10, 'days').calendar()),
    endDate: new Date(),
    key: 'selection',
  },
  utmFiltered: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: ''
  }
}

export default CompareRevenue;
