import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StyledFlex } from "utils/styles";
import moment from 'moment';
import {
   StyledDragableContainer,
   StyledCardContent,
   StyledUTMList,
   StyledUTM,
   StyledCard,
   StyledLabel,
   StyledScore,
   StyledScoreTitle,
   StyledLineChart,
   StyledFooter,
   StyledHalfWidth,
   StyledButtonPagination,
   StyledCardPagination,
   StyledLoadingContainer,
   StyledPaginationText
} from './styles';
import LineChartComp from 'components/LineChart';
import LoadingRadius from "components/LoadingRadius";
import ArrowDownIcon from 'assets/icons/menu/arrow.svg';
import NotFoundComponent from "./NotFoundResults";
import { getDashboardComparative } from 'services/dashboard';
import { parseData } from "../parserData";
import PropTypes from 'prop-types';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
   const result = Array.from(list);
   const [removed] = result.splice(startIndex, 1);
   result.splice(endIndex, 0, removed);

   return result;
};

function CompareUTM({ filtering, selectionDate, utmFiltered }) {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

   const formatDate = (date) => {
      return moment(date).format("YYYY-MM-DD");
   }

   const getData = async (oldItems = items, newPage = page) => {
      let newItem = [];
      try {
        setIsLoading(true);
        const response = await getDashboardComparative(
          formatDate(selectionDate?.startDate),
          formatDate(selectionDate?.endDate),
          utmFiltered.utm_source,
          utmFiltered.utm_medium,
          utmFiltered.utm_campaign,
          utmFiltered.utm_term,
          utmFiltered.utm_content,
          newPage,
          3
        )
        newItem = response?.data?.items
        if (!response?.data) {
          newItem = []
        }
        setItems([...oldItems, ...newItem]);
        setTotalPage(response?.data?.pages);
        setIsLoading(false);
      } catch {
        setItems([]);
        setIsLoading(false);
      }
    };

   useEffect(() => {
      setPage(1);
      setTotalPage(1);
      setItems([]);
      getData([], 1);
   }, [filtering]);

   useEffect(() => {
      if (page !== 1) {
         getData();
      }
   }, [page]);

   const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
         return;
      }

      const newItems = reorder(
         items,
         result.source.index,
         result.destination.index
      );

      setItems(newItems)
   }

   const convertFormat = (value) => {
      value = parseFloat(value);
      if (value <= 0.04) {
         return `${(value * 1440).toFixed(1)} min`
      } else if (value > 0.04 && value < 1) {
         return `${((value * 1440) / 60).toFixed(1)} hora(s)`
      } else {
         return `${value.toFixed(1)} dia(s)`
      }
   }

   const getScore = (item, key) => {
      let value = 0;
      item.map((i) => {
         if (key === 'total_auto_sAlto') {
            if (i?.score_desc === 'SUPER ALTO' || i?.score_desc === 'ALTO') {
               value += i?.value
            }
         } else {
            if (key === 'total_medio' && i?.score_desc === 'MEDIO') {
               value = i?.value
            }

            if (key === 'total_baixo' && i?.score_desc === 'BAIXO') {
               value = i?.value
            }
         }
      })
      return value;
   }

   return (
      isLoading && items.length === 0 ? (
        <StyledLoadingContainer>
          <LoadingRadius height="250px" />
        </StyledLoadingContainer>
      ) : (
        <StyledDragableContainer>
          {items.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <StyledCardPagination>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {items.map((item, index) => (
                        <Draggable key={item?.id} draggableId={item?.id} index={index}>
                          {(provided) => (
                            <StyledCardContent
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <h2>{item?.utms?.campaign}</h2>
                              <StyledUTMList>
                                {item?.utms?.campaign && <StyledUTM title={item?.utms?.campaign}>Campaign: {item?.utms?.campaign}</StyledUTM>}
                                {item?.utms?.source && <StyledUTM title={item?.utms?.source}>Source: {item?.utms?.source}</StyledUTM>}
                                {item?.utms?.content && <StyledUTM title={item?.utms?.content}>Content: {item?.utms?.content}</StyledUTM>}
                                {item?.utms?.term && <StyledUTM title={item?.utms?.term}>Term: {item?.utms?.term}</StyledUTM>}
                                {item?.utms?.medium && <StyledUTM title={item?.utms?.medium}>Medium: {item?.utms?.medium}</StyledUTM>}
                              </StyledUTMList>
                              <StyledFlex flex="column">
                                <StyledFlex flex="row" align="flex-start" justify="space-between">
                                  <StyledCard>
                                    <StyledLabel className="full-width">
                                      <h3>Total de contatos</h3>
                                      <p>{item?.total?.toLocaleString('pt-BR')}</p>
                                    </StyledLabel>
                                    <StyledFlex flex="row" align="flex-start" justify="flex-start">
                                      <StyledScore>
                                        <StyledScoreTitle color="#22943E">Score Alto e Super Alto</StyledScoreTitle>
                                        <p>{getScore(item?.scores, 'total_auto_sAlto')?.toLocaleString('pt-BR')}</p>
                                      </StyledScore>
                                      <StyledScore>
                                        <StyledScoreTitle color="#E7750A">Score Médio</StyledScoreTitle>
                                        <p>{getScore(item?.scores, 'total_medio')?.toLocaleString('pt-BR')}</p>
                                      </StyledScore>
                                      <StyledScore>
                                        <StyledScoreTitle color="#CE152F">Score Baixo</StyledScoreTitle>
                                        <p>{getScore(item?.scores, 'total_baixo')?.toLocaleString('pt-BR')}</p>
                                      </StyledScore>
                                    </StyledFlex>
                                  </StyledCard>
                                  <StyledLineChart>
                                    {item?.data?.length > 0 ? (
                                      <>
                                        <h3 className='overview'>Variação dos scores Alto e Super Alto</h3>
                                        <LineChartComp data={parseData(item?.data)} />
                                      </>
                                    ) : (
                                      <LoadingRadius height="250px" />
                                    )}
                                  </StyledLineChart>
                                </StyledFlex>
                                <StyledFooter />
                                <StyledFlex flex="row" justify="space-between">
                                  <StyledLabel><h3>Mesmo período</h3></StyledLabel>
                                  <StyledLabel>
                                    <h4>Mês anterior</h4>
                                    <span>{item?.last_month ?  item?.last_month : '-'}</span>
                                  </StyledLabel>
                                  <StyledLabel>
                                    <h4>Ano anterior</h4>
                                    <span>{item?.last_year ? item?.last_year : '-'}</span>
                                  </StyledLabel>
                                  <StyledHalfWidth>
                                    <StyledLabel>
                                      {/* <h3>Tempo para cadastro</h3>
                                      <span>{convertFormat(item?.time_to_register ? item?.time_to_register : 0.00)}</span> */}
                                    </StyledLabel>
                                  </StyledHalfWidth>
                                </StyledFlex>
                              </StyledFlex>
                            </StyledCardContent>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                {(page !== totalPage || isLoading) &&
                  <StyledButtonPagination disabled={isLoading} type='button' onClick={() => setPage(page + 1)}>
                    {isLoading ? (
                      <p>Carregando...</p>
                    ) : (
                      <StyledPaginationText>
                        <p>Carregar mais UTMs</p>
                        <img src={ArrowDownIcon} alt="Icone para ver mais" />
                      </StyledPaginationText>
                    )}
                  </StyledButtonPagination>}
              </StyledCardPagination>
            </DragDropContext>
          ) : (
            <NotFoundComponent />
          )}
        </StyledDragableContainer>
      )
    );
  }

CompareUTM.propTypes = {
   filtering: PropTypes.bool,
   selectionDate: PropTypes.shape({
      key: PropTypes.string,
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date)
   }),
   utmFiltered: PropTypes.shape({
      utm_source: PropTypes.string,
      utm_medium: PropTypes.string,
      utm_campaign: PropTypes.string,
      utm_term: PropTypes.string,
      utm_content: PropTypes.string,
   })
}

CompareUTM.defaultProps = {
   filtering: false,
   selectionDate: {
      startDate: new Date(moment().subtract(10, 'days').calendar()),
      endDate: new Date(),
      key: 'selection',
   },
   utmFiltered: {
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_term: '',
      utm_content: ''
   }
}


export default CompareUTM;

