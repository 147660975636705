import React from 'react';
import RobotIcon from 'assets/icons/report/robot.svg';
import {
  StyledMainContent,
  StyledRedText,
  StyledCommonText,
  StyledWithMargin
} from './styles';

function NotFoundComponent() {
  return (
    <StyledMainContent>
      <StyledWithMargin>
        <img src={RobotIcon} alt="icone de robô" />
        <StyledRedText>
          Ops! Resultados não encontrados
        </StyledRedText>
        <StyledCommonText>
            As informações selecionadas no filtro não correspondem aos dados utilizados para fornecer este gráfico. Verifique as informações filtradas.
        </StyledCommonText>
      </StyledWithMargin>
    </StyledMainContent>
  );
}

export default NotFoundComponent;
