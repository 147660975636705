import styled from 'styled-components';

export const StyledList = styled.ul`
  width: 100%;
  list-style-type: none;
`;

export const StyledDragableContainer = styled.div`
  width: 100%;
`;

// CARD
export const StyledCardContent = styled.div`
  background: #FFFFFF;
  width: 99%;
  margin: 10px auto;
  padding: 16px 32px;
  box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
`;

export const StyledUTMList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

export const StyledUTM = styled.div`
  background-color: #EDEFF4;
  color: #667A8C;
  border-radius: 5px;
  padding: 3px 5px;
  margin-right: 5px;
  font-size: 12px;
  white-space: nowrap; 
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCard = styled.div`
  border-radius: 4px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 11px 0;
`;

export const StyledLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &.full-width {
    width: 100%;
  }

  h3 {
    color: #667A8C;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  h4 {
    color: #667A8C;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
  }

  span {
    font-size: 16px;
    line-height: 27px;
    color: #2A2A2A;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    line-height: 27px;
    color: #262626;
  }
`;

export const StyledScore = styled.div`
  width: 100%;
  margin-right: 42px;
`;

export const StyledScoreTitle = styled.h3`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.color};
`;

export const StyledLineChart = styled.div`
  width: 50%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 0;
  border-radius: 5px;

  h3 { 
    color: #667A8C;
    font-weight: 500;
    font-size: 14px;
    width: max-content;
    margin-bottom: 20px;
    align-self: flex-start;
  }
`;

export const StyledFooter = styled.div`
  border-top: 1px solid #BBC6D7;
  padding-bottom: 25px;
  width: 100%;
`;

export const StyledCardPagination = styled.div`
  width: 100%;
`;

export const StyledLoadingContainer = styled.div`
  height: calc(100vh - 170px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonPagination = styled.button`
  width: 100%;
  background-color: #EDEFF4;
  color: #1B407A;
  padding: 25px 0;
  height: 70px;
  cursor: pointer;
  text-align: center;
  border: none;

  :disabled {
    color: #BBC6D7;
    cursor: default;
  }
`;

export const StyledPaginationText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
`;

export const StyledHalfWidth = styled.div`
  width: 50%;
`;