import styled from "styled-components";

export const StyledMainContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;

export const StyledIcon = styled.img`
    width: 25px;
    height: 25px;

    &.rotate {
        transform: rotate(180deg);
    }
`;

export const StyledIconButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;

    &:disabled {
        opacity: 0;
        cursor: default;
    }
`;
