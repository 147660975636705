import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 300px); */
  width: 100%;
  align-items: center;
  margin: auto;
`;

export const StyledFilter = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 20px 0px;
  padding: 0 50px;
`;

export const StyledTripleCharts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.12);
  padding: 32px 36px;
  margin-left: 10px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #00213F;
    margin-bottom: 16px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #1B407A;
  }

  .content {
    padding: 32px 40px 32px 40px;
    .title {
      font-size: 14px;
      margin: 0px;
    }
  
    .subtitle {
      font-size: 14px;
      margin-bottom: 50px;
    }
    
    .value {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #667A8C;
    }
  
    .empty {
      height: 190px;
  
      .bar {
        width: 18px;
      }
    }
  }
`;

export const StyledCharts = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* width: 50vw;
  max-width: 800px; */
`;

export const StyledUniqueChart = styled.div`
  box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 12%);
`;

export const StyledChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

export const StyledLegend = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 10px;
  font-size: 12px;
  color: #727272;
  text-align: center;
`;

export const StyledLegendColor = styled.div`
  min-width: 9px;
  min-height: 9px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border-radius: 50%;
`;
