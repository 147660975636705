import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import hubspotIcon from 'assets/icons/settings/hubspot.svg';
import vtexIcon from 'assets/icons/settings/vtex.svg';
import facebookIcon from 'assets/icons/settings/facebook.svg';
import googleIcon from 'assets/icons/settings/google.svg';
import Card from './Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from 'components/Button';
import ModalInput from "./Input";
import {
  StyledMainContent,
  StyledTitle,
  StyledWrapper,
  StyledModalTitle,
  StyledModalContent,
  StyledModalText,
  StyledFeedbackContent,
  StyledSelectContainer
} from './styles';
import LoadingRadius from "components/LoadingRadius";
import { postQueue } from "services/settings";
import FacebookLogin from 'react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import { postFacebook, getFacebookAccount, putFacebookAccount } from "services/facebook";
import { postGoogle, getGoogleAccount, putGoogleAccount } from "services/google";
import { useGlobalAlert } from "contexts/alert";
import SelectComponent from "components/Select";

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

const Settings = () => {
  const [openVtexModal, setOpenVtexModal] = useState(false);
  const [, setAlert] = useGlobalAlert();
  const cookies = Cookies.get();

  // VTEX states
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [appToken, setAppToken] = useState('');
  const [appKey, setAppKey] = useState();
  const [vtexLoading, setVtexLoading] = useState(false);

  // Facebook states
  const [openFaceModal, setOpenFaceModal] = useState(false);
  const [faceAccountOptions, setFaceAccountOptions] = useState([]);
  const [selectedFaceAccount, setSelectedFaceAccount] = useState('');
  const [faceLoading, setFaceLoading] = useState(false);

  // Google states
  const [openGoogleModal, setOpenGoogleModal] = useState(false);
  const [googleAccountOptions, setGoogleAccountOptions] = useState([]);
  const [selectedGoogleAccount, setSelectedGoogleAccount] = useState('');
  const [googleLoading, setGoogleLoading] = useState(false);

  const handleClickOpen = () => {
    setOpenVtexModal(true);
  };

  const handleClose = () => {
    setOpenVtexModal(false);
  };

  const getFacebookAccounts = async () => {
    try {
      const response = await getFacebookAccount();
      setFaceAccountOptions(response?.data?.data);
    } catch {
      setFaceAccountOptions([]);
    }
  }

  const getGoogleAccounts = async () => {
    try {
      const response = await getGoogleAccount();
      setGoogleAccountOptions(response?.data?.accounts);
    } catch {
      setGoogleAccountOptions([]);
    }
  }

  useEffect(() => {
    getGoogleAccounts();
  }, [openGoogleModal]);

  useEffect(() => {
    getFacebookAccounts();
  }, [openFaceModal]);

  const handleSubmitFaceAccount = async () => {
    setFaceLoading(true)
    const body = {
      "account_id": selectedFaceAccount
    };
    try {
      await putFacebookAccount(body);
      setAlert({
        title: 'Integração realizada com sucesso!',
        type: 'success', // success, error, warning, question
        open: true,
        text: 'O sistema levará cerca de 24h para atualizar os dados. Você receberá um e-mail do sales.intelligence@neurotech.com.br quando estiver tudo pronto.',
        confirmButton: () => { },
      })
    } catch (error) {
      setAlert({
        title: 'Houve um erro na integração, tente novamente mais tarde.',
        type: 'error', // success, error, warning, question
        open: true,
        text: `code: ${error.response.status}, ${error.response.data.detail}`,
        confirmButton: () => { },
      })
    }
    setFaceLoading(false);
    setOpenFaceModal(false);
  }

  const handleSubmitGoogleAccount = async () => {
    setGoogleLoading(true)
    const body = {
      "account_id": selectedGoogleAccount
    };
    try {
      await putGoogleAccount(body);
      setAlert({
        title: 'Integração realizada com sucesso!',
        type: 'success', // success, error, warning, question
        open: true,
        text: 'O sistema levará cerca de 24h para atualizar os dados. Você receberá um e-mail do sales.intelligence@neurotech.com.br quando estiver tudo pronto.',
        confirmButton: () => { },
      })
    } catch (error) {
      setAlert({
        title: 'Houve um erro na integração, tente novamente mais tarde.',
        type: 'error', // success, error, warning, question
        open: true,
        text: `code: ${error.response.status}, ${error.response.data.detail}`,
        confirmButton: () => { },
      })
    }
    setGoogleLoading(false);
    setOpenGoogleModal(false);
  }

  const handleSubmit = async () => {
    setVtexLoading(true);
    const body = {
      appToken,
      accountName,
      appKey
    }
    try {
      await postQueue(body);
      setAlert({
        title: 'Integração realizada com sucesso!',
        type: 'success', // success, error, warning, question
        open: true,
        text: 'O sistema levará cerca de 24h para atualizar os dados. Você receberá um e-mail do sales.intelligence@neurotech.com.br quando estiver tudo pronto.',
      })
    } catch (error) {
      setAlert({
        title: 'Houve um erro na integração, tente novamente mais tarde.',
        type: 'error', // success, error, warning, question
        open: true,
        text: `code: ${error.response.status}, ${error.response.data.detail}`,
      })
    }
    handleClose();
    setVtexLoading(false);
  }

  const faceCallback = async (response) => {
    const body = {
      token: response.accessToken,
      name: cookies.client_name,
      access_token: cookies.token
    }
    try {
      await postFacebook(body);
      setOpenFaceModal(true)
    } catch (error) {
      setAlert({
        title: 'Erro ao se registrar ao conector do Facebook.',
        type: 'error', // success, error, warning, question
        open: true,
        text: `Houve um erro ao tentarmos cadastrar seu usuário ao conector facebook, tente novamente mais tarde. \ncode: ${error.response.status}, ${error.response.data.detail}`,
      })
    }
  }

  const googleCallback = async (response) => {
    const body = {
      code: response.code,
      client_name: cookies.client_name,
    }
    try {
      await postGoogle(body);
      setOpenGoogleModal(true)
    } catch (error) {
      setAlert({
        title: 'Erro ao se registrar ao conector do Google.',
        type: 'error', // success, error, warning, question
        open: true,
        text: `Houve um erro ao tentarmos cadastrar seu usuário ao conector google, tente novamente mais tarde. \ncode: ${error.response.status}, ${error.response.data.detail}`,
      })
    }
  }

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      googleCallback(response)
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/adwords',
    redirect_uri: `${process.env.REACT_APP_HOST}`,
    onError: errorResponse => console.log(errorResponse),
  });

  return (
    <StyledMainContent>
      <StyledTitle>Integrações</StyledTitle>
      <StyledWrapper>
        <Card icon={hubspotIcon} title="Hubspot" onClick={() => { window.open(`${process.env.REACT_APP_HUBSPOT_APP}`, "_blank") }}>
          <p>
            O Hubspot CRM é criado para dar suporte à metodologia inbound a ajudar você e seus clientes a crescer melhor. Realize o cadastro e instale o nosso aplicativo para realizar a integração com o Hubspot.
          </p>
        </Card>
        <Card icon={vtexIcon} title="VTEX" onClick={() => handleClickOpen()}>
          <p>
            Ao integrar o Sales Intelligence com a Vtex você nos permite coletar listas de contatos, pedidos e produtos que contribuirão para classificar os clientes com o score de propensão à compra.
          </p>
        </Card>
        <Card icon={googleIcon} title="Google Ads" onClick={() => login()}>
          <p>
            Ao integrar o Sales Intelligence com o Google Ads você nos permite coletar listas de campanhas, grupos de anúncios e ADs que auxiliará no cálculo do CPLQ.
          </p>
        </Card>
        <Card hasInstall={false} icon={facebookIcon} title="Facebook" onClick={() => handleClickOpen()}>
          <p>
            Ao integrar o Sales Intelligence com o Facebook Ads você nos permite coletar listas de campanhas, grupos de anúncios e ADs que auxiliará no cálculo do CPLQ.
          </p>
          <FacebookLogin
            appId={facebookAppId}
            fields="name,email,picture"
            scope="ads_management,ads_read"
            callback={faceCallback}
            textButton={"Login Facebook"}
            returnScopes
            size="small"
            version="3.0"
          />
        </Card>
      </StyledWrapper>

      <Dialog open={openVtexModal} onClose={handleClose}>
        <StyledModalContent>
          <StyledModalTitle>Integrar VTEX</StyledModalTitle>
          <StyledModalText>
            A integração com a Vtex nos permite coletar listas de contatos,
            pedidos e produtos que nos ajudam a classificar seus clientes com o score de propensão à compra.
          </StyledModalText>
          <form>
            <ModalInput type="text" placeholder="Insira sua account name" label="Account name" value={accountName} onChange={(e) => setAccountName(e.target.value)} />
            <ModalInput showEye type="text" placeholder="Insira sua Vtex API appkey" label="Vtex API appkey" value={appKey} onChange={(e) => setAppKey(e.target.value)} />
            <ModalInput showEye type="text" placeholder="Insira sua Vtex API apptoken" label="Vtex API apptoken" value={appToken} onChange={(e) => setAppToken(e.target.value)} />
            <DialogActions>
              <Button background="#FFF" colorText="#667A8C" onClick={handleClose}>Cancelar</Button>
              <Button className="button-intregation" disabled={!(accountName && appKey && appToken) || vtexLoading} onClick={() => handleSubmit()}>{
                !vtexLoading ? 'Integrar' : (
                  <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#fff" />
                )}</Button>
            </DialogActions>
          </form>
        </StyledModalContent>
      </Dialog>
      <Dialog open={openFaceModal && faceAccountOptions.length > 0}>
        <StyledFeedbackContent>
          <StyledModalTitle>Agora selecione em qual conta você deseja integrar!</StyledModalTitle>
          <StyledSelectContainer>
            <SelectComponent labelKey="account_id" valueKey="account_id" options={faceAccountOptions} label="Account ID" state={selectedFaceAccount} onChange={setSelectedFaceAccount} />
          </StyledSelectContainer>
          <DialogActions>
            <Button className="button-intregation" disabled={!selectedFaceAccount || faceLoading} onClick={() => handleSubmitFaceAccount()}>{
              !faceLoading ? 'Integrar' : (
                <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#fff" />
              )}</Button>
          </DialogActions>
        </StyledFeedbackContent>
      </Dialog>
      <Dialog open={openGoogleModal}>
        <StyledFeedbackContent>
          <StyledModalTitle>Agora selecione em qual conta você deseja integrar!</StyledModalTitle>
          <StyledSelectContainer>
            <SelectComponent labelKey="label" valueKey="value" options={googleAccountOptions} label="Account ID" state={selectedGoogleAccount} onChange={setSelectedGoogleAccount} />
          </StyledSelectContainer>
          <DialogActions>
            <Button className="button-intregation" disabled={!selectedGoogleAccount || googleLoading} onClick={() => handleSubmitGoogleAccount()}>{
              !googleLoading ? 'Integrar' : (
                <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#fff" />
              )}</Button>
          </DialogActions>
        </StyledFeedbackContent>
      </Dialog>
    </StyledMainContent>
  );
};

export default Settings;
