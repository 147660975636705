import moment from 'moment';

export const parserData = (data) => {
  const arr = [];

  data?.map((day_object) => {
    arr.push({
      date: moment(day_object.date).format('DD/MM'),
      alto: day_object?.sum_valor_pago_alto / day_object?.sum_alto,
      sAlto: day_object?.sum_valor_pago_super_alto / day_object?.sum_super_alto,
    })
  })

  return arr;

};
