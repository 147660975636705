import styled from 'styled-components';

export const StyledMainContent = styled.form`
  display: flex;
	flex-direction: column;
	position: fixed;
	right: 0;
	top:0;
	background-color: white;
	height: 100%;
	z-index: 10;
	color: #00213F;
	box-shadow: 0px 1px 14px 2px rgba(0, 0, 0, 0.12);
	opacity: ${(props) => props.visible ? 1 : 0};
	width: ${(props) => props.visible ? '380px' : 0};
	transition: all .2s ease-in-out;

	h1 {
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 30px;
	}

	h2 {
		color: #1B407A;
		font-size: 16px;
		font-weight: 500;
	}

	.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label  {
		color: #667A8C;
	}
`;

export const StyledUTMs = styled.div`
	padding: 30px 19px 0 19px;
    position: relative;
    overflow-y: scroll;
`;

export const StyledDateContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-direction: column;
	margin-bottom: 30px;
`;

export const StyledInputDate = styled.input`
	color: #E7750A;
    background-color: rgb(255, 222, 191, 0.5);
	width: 120px;
	::-webkit-inner-spin-button,
	::-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none;
	}
	border: 1px solid #E7750A;
	border-radius: 5px;
	border-radius: 5px;
	padding: 9px 8px;
	outline: none;
`;

export const StyledCalendarButton = styled.button`
	background-color: #E7750A;
	width: 40px;
	height: 40px;
	border: none;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

export const StyledFilterHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledFilterContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;

  h3 {
    margin-bottom: 15px;
  }

  button {
    align-self: flex-end;
  }
`;

export const StyledSeeMore = styled.a`
	cursor: pointer;
	font-size: 12px;
	color: #1B407A;
	margin-bottom: 15px;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;

	img {
		margin-left: 6px;
	}
`;

export const StyledOrangeTip = styled.div`
  color: #E7750A;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 0;

  img {
    margin-right: 5px;
  }
`;

export const StyledSelectContent = styled.div`
  margin: 9px 0;
  width: 100%;
  p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .css-1rhbuit-multiValue {
    background-color: #DBDDE6;
    color: #1B407A;
  }

  .css-2m9kme-MuiFormControl-root {
    width: 100%;
  }

  .css-12jo7m5 {
    color: #1B407A;
  }

  .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 16.5px 0 16.5px 14px;
	color: #1B407A;
  }

  .MuiChip-deleteIcon {
    color: #E7750A;
  }

  .MuiAutocomplete-tag {
    color: #E7750A;
    background-color: rgb(255, 222, 191, 0.5);
  }

  .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #BBC6D7;
  }

	/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
		color: yellow !important;
	} */
`;

export const StyledFooter = styled.div`
	display: flex;
	margin-top: auto;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	border-top: 1px solid #DBDDE6;
	width: 100%;
`;

export const StyledButton = styled.button`
	padding: 10px 20px;
	border: none;
	color: #FFF;
	border-radius: 5px;
	background-color: #1B407A;
	cursor: pointer;

	&.no-background {
		background-color: white;
		color: #8295B6;
	}

	:disabled {
		background-color: #EDEFF4;
		color: #667A8C;
		cursor: default;
	}
`;

export const StyledCloseButton = styled.button`
	cursor: pointer;
	margin-bottom:10px;
	border: none;
	background-color: transparent;
`;