import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 20px;

  h2 {
    font-size: 18px;
    font-weight: 500;
    align-self: flex-start;
    line-height: 27px;
    color: #262626;
  }
`;

export const StyledDashFilter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledDash = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 1500px; */
  height: 100%;
  justify-content: space-between;
`;

export const StyledCards = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const StyledChart = styled.div`
  width: 100%;
  height: 360px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  h3 { 
    color: #667A8C;
    font-weight: 500;
    font-size: 14px;
    width: max-content;
    margin-bottom: 20px;
    align-self: flex-start;
  }
`;

export const StyledLegend = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
`;

export const StyledLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 10px;
  font-size: 12px;
  color: #727272;
`;

export const StyledLegendColor = styled.div`
  min-width: 9px;
  min-height: 9px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border-radius: 50%;
`;

export const StyledChartHeader = styled.div`
  margin-bottom: 15px;
  margin-left: 22px;
  h2 {
    margin-bottom: 20px;
  }
`;

export const StyledUtmLabel = styled.p`
  color: ${(props) => props.isDisabled ? '#BBC6D7' : '#00213F'};
`;

export const StyledLineChart = styled.div`
  background-color: white;
  width: calc(100% - 22px);
  height: 210px;
  margin: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 5px;

  h3 { 
    color: #667A8C;
    font-weight: 500;
    font-size: 14px;
    width: max-content;
    align-self: flex-start;
    margin-bottom: 20px;
  }
`;

export const StyledHeadCards = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;

export const StyledAsideCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 25%;
  min-height: 360px;
  box-sizing: border-box;
`;

export const StyledChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const StyledChatDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const StyledButton = styled.button`
  background-color: #E7750A;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .counter {
    background-color: #FFF;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: #E7750A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
  }

  img {
    margin-right: 10px;
  }
`;

export const StyledHeader = styled.div`
  background-color: #EDEFF4;
  padding: 10px 0;
  width: 100%;
  z-index: 3;
  position: sticky;
  top: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
