import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  #customized-button {
    margin-right: 20px;
    background-color: white;
    color: #1B407A;
    text-transform: lowercase;
  }

  .menu-icon {
    margin-right: 10px;
  }
`;
