/* eslint-disable react/prop-types */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  StyledMainContent,
  StyledBarContainer,
  StyledBar,
  StyledContainer,
  StyledBarEmpty,
  StyledValue
} from './styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const colors = {
  'SUPER ALTO': '#22943E',
  ALTO: '#8CC14C',
  MEDIO:'#E7750A',
  BAIXO: '#CE152F',
  SC: '#C8C8C8'};

function Chart({ title, total, tooltip, data }) {
  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    if(data?.length) {
      const time = data.map(object => {
        return object.value;
      });
      setMaxValue(Math.max(...time))
    }
    return
  }, [data]);

  useEffect(() => {
   
  }, [data]);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(13, 13, 13, 0.85)',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding: '10px',
      maxWidth: '250px'
    },
  }));

  const BarComponent = ({ item }) => {
    return (
      <CustomTooltip
        title={
          <Fragment>
            {`${((item?.value / total) * 100)?.toFixed(1)}%`}
          </Fragment>
        }
        arrow
        followCursor
        placement="right-start">
          <StyledBarContainer>
            <StyledValue className='value' distance={`${(100 * item?.value) / maxValue}%`}>{item?.value}</StyledValue>
            <StyledBarEmpty className='empty'>
              <StyledBar className='bar' color={colors[item?.desc_score]} height={`${(100 * item?.value) / maxValue}%`} />
            </StyledBarEmpty>
          </StyledBarContainer>
      </CustomTooltip>
    );
  }

  BarComponent.propTypes = {
    item: PropTypes.shape({
      desc_score: PropTypes.string,
      value: PropTypes.number
    }),
  }

  return (
    <StyledMainContent className='content'>
      <h1 className='title'>{title}</h1>
      <h2 className='subtitle'>{`${total || 0} no total`}</h2>
      <StyledContainer>
        {maxValue && (
          <>
            <BarComponent item={data.find((item) => item?.desc_score === 'SUPER ALTO')} />
            <BarComponent item={data.find((item) => item?.desc_score === 'ALTO')} />
            <BarComponent item={data.find((item) => item?.desc_score === 'MEDIO')} />
            <BarComponent item={data.find((item) => item?.desc_score === 'BAIXO')} />
            <BarComponent item={data.find((item) => item?.desc_score === 'SC')} />
          </>
        )}
      </StyledContainer>
    </StyledMainContent>
  )
}

Chart.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  tooltip: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    desc_score: PropTypes.string,
    value: PropTypes.number
  })),
}

Chart.defaultProps = {
  title: '',
  total: 0,
  tooltip: '',
  data: [{
    desc_score: '',
    value: 0
  }]
}

export default Chart;
