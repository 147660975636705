import teamIcon from '../../assets/icons/navbar/team.svg';
import configIcon from '../../assets/icons/navbar/config.svg';
import homeIcon from '../../assets/icons/navbar/home.svg';
import routeIcon from '../../assets/icons/navbar/route.svg';
import analiticosIcon from '../../assets/icons/navbar/analiticos.svg';
import teamIconActive from '../../assets/icons/navbar/team-active.svg';
import configIconActive from '../../assets/icons/navbar/config-active.svg';
import homeIconActive from '../../assets/icons/navbar/home-active.svg';
import routeIconActive from '../../assets/icons/navbar/route-active.svg';
import analiticosIconActive from '../../assets/icons/navbar/analiticos-active.svg';

export const SideBarData = [
    {
        title: "Relatórios",
        path: "/app",
        activeIcon: homeIconActive,
        icon: homeIcon,
        cName: "nav-text"
    },
    // {
    //     title: "Personas",
    //     path: "/app/personas",
    //     activeIcon: teamIconActive,
    //     icon: teamIcon,
    //     cName: "nav-text"
    // },
    {
        title: "Dados analíticos",
        path: "/app/dados-analiticos",
        activeIcon: analiticosIconActive,
        icon: analiticosIcon,
        cName: "nav-text"
    },
    // {
    //     title: "Jornada consumidor",
    //     path: "/app/jornada-do-cliente",
    //     activeIcon: routeIconActive,
    //     icon: routeIcon,
    //     cName: "nav-text"
    // },
    {
        title: "Configurações",
        path: "/app/configuracoes",
        activeIcon: configIconActive,
        icon: configIcon,
        cName: "nav-text"
    }
  ];