import React from 'react';
import { Switch, useRouteMatch, Redirect, Route } from 'react-router-dom';

import Login from '../pages/Login';

const AuthRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/account`} component={Login} />
      <Redirect to={`${path}/account`} />
    </Switch>
  );
};

export default AuthRoutes;
