import React, { useMemo, useState } from 'react';
import {
  ComposedChart,
  CartesianGrid,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  StyledMainContent,
  StyledIcon,
  StyledIconButton
} from './styles';
import PropTypes from 'prop-types';
import ArrowRight from 'assets/icons/report/arrow-right-chart.svg';
import Tolltip from './Tooltip';

function MixedChart({ data }) {
  const pageSize = 31;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <StyledMainContent>
      <StyledIconButton disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
        <StyledIcon className='rotate' src={ArrowRight} alt='Icone de seta' />
      </StyledIconButton>
      <ResponsiveContainer width="90%" height="100%">
        <ComposedChart data={currentTableData}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis axisLine={false} tick={{ fill: '#727272', fontSize: 12 }} dataKey="date" />
          <YAxis axisLine={false} tick={{ fill: '#727272', fontSize: 12 }} />
          <Bar dataKey="sc" barSize={17} stackId="bar" fill="#C8C8C8" radius={[0, 0, 10, 10]} />
          <Bar dataKey="baixo" barSize={17} stackId="bar" fill="#CA0009" />
          <Bar dataKey="medio" barSize={17} stackId="bar" fill="#E7750A" />
          <Bar dataKey="alto" barSize={17} stackId="bar" fill="#8CC14C" />
          <Bar dataKey="sAlto" barSize={17} stackId="bar" fill="#22943E" radius={[10, 10, 0, 0]} />
          <Line type="linear" strokeWidth={3} dataKey="conversão" stroke="#000000" fill="#000000" activeDot={{ r: 6 }} />
          <Tooltip
            content={<Tolltip />}
            cursor={true}
            position="top"
          />
        </ComposedChart>
      </ResponsiveContainer>
      <StyledIconButton disabled={currentPage === Math.ceil(data.length / pageSize)} onClick={() => setCurrentPage(currentPage + 1)}>
        <StyledIcon src={ArrowRight} alt="Icone de seta" />
      </StyledIconButton>
    </StyledMainContent>
  );
}

MixedChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    sc: PropTypes.number,
    baixo: PropTypes.number,
    medio: PropTypes.number,
    alto: PropTypes.number,
    sAlto: PropTypes.number,
  }),
  ),
}

MixedChart.defaultProps = {
  data: [{}]
}

export default MixedChart;
