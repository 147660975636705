import React, { useEffect, useState } from "react";
import moment from 'moment';
import LoadingRadius from "components/LoadingRadius";
import TableComponent from 'components/TableComponent';
import { useGlobalAlert } from "contexts/alert";
// import SearchBar from 'components/SearchBar';
import { getContact, getDownload } from "../../services/contact";
import Button from "components/Button";
import DownloadIcon from "assets/icons/analitics/download.svg";
import {
  StyledTableContent,
  StyledMainContent,
  StyledTableHeader,
  StyledSearchContainer
} from './styles';

const AnalyticData = () => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [, setAlert] = useGlobalAlert();

  const columns = [
    { id: 'email', secID: 'cpf', label: 'E-mail/CPF', minWidth: 170 },
    {
      id: 'score_desc',
      label: 'Score',
      minWidth: 170,
      mapping: {
        'medio': 'Médio',
        'baixo': 'Baixo',
        'alto': 'Alto',
        'super alto': 'Super alto'
      }
    },
    {
      id: 'date',
      label: 'Data de atualização',
      minWidth: 170,
      // Insere 3hrs por conta do GTM -3
      format: (value) => moment(new Date(value)).add('3', 'hours').format('DD/MM/YYYY'),
    },
  ];
  
  const getData = async () => {
    setIsLoading(true);
    const response = await getContact(page, rowsPerPage);
    setIsLoading(false);
    setTotalRows(response?.data?.total_page);
    setTableData(response?.data?.data);
  };

  const getDownloadData = async () => {
    setIsLoadingDownload(true);
    const response = await getDownload();

    if (response?.status === 200) {
      setAlert({
        title: 'Solicitação de download realizada com sucesso!',
        type: 'success',
        open: true,
        text: 'Você receberá um e-mail com atualizações sobre a exportação dos dados em até 24hrs.',
        confirmButton: () => {},
      })
    } else {
      setAlert({
        title: 'Houve algo de errado na sua solicitação de download.',
        type: 'error',
        open: true,
        text: 'Ops, algo de errado aconteceu! Não foi possível fazer iniciar a exportação. Tente novamente mais tarde.',
        confirmButton: () => {},
      })
    }
    
    setIsLoadingDownload(false);
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  return (
    <StyledMainContent>
      <h2>Dados analíticos</h2>
      <StyledTableContent>
        <StyledTableHeader justify="space-between" flex="row">
          <StyledSearchContainer>
            {/* <SearchBar disabled onChange={(e) => setSearched(e.target.value)} value={searched} /> */}
            <Button onClick={getDownloadData} disabled={isLoadingDownload} className="button-download" colorText="#fff">
              {isLoadingDownload ? (<LoadingRadius className="downloading" color='white' height="15px" lWidth='15px' />) : (
                <img src={DownloadIcon} alt="icone de download" />
              )}
              <p>Download</p>
            </Button>
          </StyledSearchContainer>
        </StyledTableHeader>
        <TableComponent
          count={totalRows}
          rows={tableData}
          columns={columns}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          isLoading={isLoading}
        />
      </StyledTableContent>
    </StyledMainContent>
  );
};

export default AnalyticData;
