import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AnalyticData from "../pages/AnalyticData/AnalyticData";
import Personas from "../pages/Personas/Personas";
import Reports from "../pages/Reports/index";
import Settings from "../pages/Settings";
import Journey from "pages/Journey";

import Base from "components/Base";

const AppRoutes = () => {

  return (
    <Base>
      <Switch>
        <Route exact path={`/app`} component={() => <Reports />} />
        <Route path={`/app/dados-analiticos`} component={() => <AnalyticData />} />
        <Route path={`/app/personas`} component={() => <Personas />} />
        {/* <Route path={`/app/jornada-do-cliente`} component={() => <Journey />} /> */}
        <Route path={`/app/configuracoes`} component={() => <Settings />} />
        <Redirect to={`/app`} />
      </Switch>
    </Base>
  );
};

export default AppRoutes;
