import React from "react";
import "./App.css";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { GoogleOAuthProvider } from '@react-oauth/google';
import AuthProvider from "contexts/auth";
import AlertProvider from "contexts/alert";

function App() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
          <AlertProvider>
            <AuthProvider>
                <Routes />
            </AuthProvider>
          </AlertProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
