import React from 'react';
import {
  StyledMainContent,
  StyledText
} from './styles';
import NotFoundResult from 'assets/no-results.svg';

function NotFoundResults() {
  return (
    <StyledMainContent>
      <StyledText>
        <h2>Ops!... Nenhum resultado encontrado</h2>
        <p>Não conseguimos encontrar campanhas com os dados filtrados. Por favor, confira os itens selecionados no filtro e tente novamente</p>
      </StyledText>
      <img src={NotFoundResult} alt="imagem de não encontrado" />
    </StyledMainContent>
  );
}

export default NotFoundResults;
