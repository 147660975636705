import styled from 'styled-components';

export const StyledMainContent = styled.div`
  width: 99%;
  background-color: #FFF;
  padding: 19px;
  box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .css-wjh20t-MuiPagination-ul{
    margin-top: 10px;
  }

  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    border: 1px solid #8295B6;
    color: #8295B6;
  }

  [aria-current]:not([aria-current="false"]) {
    border: 1px solid #1B407A;
    color: #1B407A;
  }
`;

export const StyledLoadingContainer = styled.div`
  height: calc(100vh - 170px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTable = styled.div`
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledTableRow = styled.div`
  border: 1px solid #DBDDE6;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;

  &.header {
    background-color: #EDEFF4;
    border: 1px solid #DBDDE6;
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const StyledTableHeader = styled.div`
  text-align: ${(props) => props.align === 'start' ? 'start' : 'center'};
  padding: 5px 21px;
  color: #00213F;
  width: ${(props) => props.width};
`;

export const StyledTableContent = styled.div`
  padding: 13px 21px;
  color: #667A8C;
  width: ${(props) => props.width};
  text-align: ${(props) => props.align === 'start' ? 'start' : 'center'};
`;

export const StyledTag = styled.div`
  background-color: #EDEFF4;
  border-radius: 5px;
  margin: 3px;
  padding: 3px;
  white-space: nowrap; 
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledScoreTag = styled.p`
  color: ${(props) => props.color};
  margin-right: 8px;
  font-size: 12px;
`;

export const StyledTagScore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledScoresTagContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;