import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  padding-right: 60px;

  h2 {
    color: #E7750A;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #00213F;
  }

  img {
    width: 730px;
    height: 530px;
  }
`;

export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 360px;
`;
