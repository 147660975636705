import styled from 'styled-components';

export const StyledMainContent = styled.div`
  margin-bottom: 10px;
  width: 100%;
  position: absolute;
  top: 170px;
  left: 18px;
  z-index: 10;
  transition: all .3s ease-in-out;

  .rdrCalendarWrapper {
    width: 90%;
    background-color: #EDEFF4;
  }

  .rdrDayNumber span {
    color: #1d2429 !important;
  }

  .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    color: rgb(255,222,191,0.5) !important;
  }

  .rdrMonthAndYearWrapper {
    padding-top: 0px;
    height: 40px;
  }

  .rdrMonth {
    width: 100%;
  }

  .rdrMonth {
    padding: 0 0.833em 5px 0.833em !important;
}

  .rdrDays {
    height: 250px;
  }
`;