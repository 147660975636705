import styled from 'styled-components';

export const StyledTooltip = styled.div`
  background-color: rgba(13, 13, 13, 0.93);
  color: white;
  padding: 12px;
  /* border-radius: 15px; */
  border: none;
  outline: none;

  p {
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 3px;
  }

  h1 {
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
`;