import moment from 'moment';

export const parseData = (data) => {
    let j = 0;
    const scores = {
      "ALTO": "alto",
      "BAIXO": "baixo",
      "SC": "sc",
      "MEDIO": "medio",
      "SUPER ALTO": "sAlto",
    };
  
    const objData = { date: "", alto: null, baixo: null, sAlto: null, medio: null, sc: null };
    const array_results = [];
    
    data?.forEach((rows) => {
      if (array_results.length === 0) {
        objData.date = moment(rows.date).format('DD/MM');
        objData[scores[rows.score_desc]] = rows.score_count;
        array_results.push({ ...objData });
      } else if (array_results[j].date !== moment(rows.date).format('DD/MM')) {
        j += 1;
  
        objData.alto = null;
        objData.sAlto = null;
        objData.baixo = null;
        objData.medio = null;
        objData.sc = null;
        objData.date = moment(rows.date).format('DD/MM');
        objData[scores[rows.score_desc]] = rows.score_count;
        array_results.push({ ...objData });
      } else {
        array_results[j][scores[rows.score_desc]] = rows.score_count;
      }
    });

    return array_results;
  };
  