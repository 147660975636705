import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  color: ${(props) => props.colorText};
  background-color: ${(props) => props.background ?? '#1B407A'};
  cursor: pointer;
  border: none;
  padding: 10px 24px;
  border-radius: 4px;
  font-weight: 500;

  img {
    margin-right: 7px;
  }

  :hover {
    opacity: 0.7;
  }

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
