import React, { useState, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  StyledMainContent,
  StyledIcon,
  StyledIconButton
} from './styles';
import Tolltip from './Tooltip';
import ArrowRight from 'assets/icons/report/arrow-right-chart.svg';
import PropTypes from 'prop-types';

function LineChartComp({ data }) {
  const pageSize = 31;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  return (
    <StyledMainContent>
      <StyledIconButton disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
        <StyledIcon className='rotate' src={ArrowRight} alt='Icone de seta' />
      </StyledIconButton>
      <ResponsiveContainer width="100%" height="90%">
        <LineChart
          width={500}
          height={300}
          data={currentTableData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis axisLine={false} tick={{ fill: '#8295B6', fontSize: 10 }} dataKey="date" />
          <YAxis hide={true} axisLine={false} tick={{ fill: '#8295B6', fontSize: 10 }} />
          <Tooltip
            content={<Tolltip />}
            cursor={true}
            position="top"
          />
          <Line strokeWidth={2} dataKey="sAlto" stroke="#22943E" dot={false} />
          <Line strokeWidth={2} dataKey="alto" stroke="#8CC14C" dot={false} />
        </LineChart>
      </ResponsiveContainer>
      <StyledIconButton disabled={currentPage === Math.ceil(data.length / pageSize)} onClick={() => setCurrentPage(currentPage + 1)}>
        <StyledIcon src={ArrowRight} alt="Icone de seta" />
      </StyledIconButton>
    </StyledMainContent>
  );
}

LineChartComp.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    sc: PropTypes.number,
    baixo: PropTypes.number,
    medio: PropTypes.number,
    alto: PropTypes.number,
    sAlto: PropTypes.number,
  }),
  ),
}

LineChartComp.defaultProps = {
  data: [{}]
}

export default LineChartComp;
