import React, { Component, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUser } from 'services/user';
import { useAuth } from 'contexts/auth';

function PrivateRoute({ component: Comp, location, ...rest }) {
  const { signOut } = useAuth();
  const cookies = Cookies.get();

  const clearCookies = async () => {
    let isLogged = false;
    try {
      // Checks token and returns a bool
      isLogged = await getUser();
      if (isLogged === false) {
        signOut();
      }
    } catch (err) {
      signOut();
    }

    return isLogged
  }

  useEffect(() => {
    clearCookies()
  }, [cookies]);

  return (
    <Route
      {...rest}
      render={props =>
        cookies.access_token ? (
          <Comp {...props} />
        ) : (
          <Redirect to={{ pathname: "/auth/account", state: { from: location } }} />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.exact({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string,
    state: PropTypes.string,
  }),
};

PrivateRoute.defaultProps = {
  component: Component,
  location: {
    pathname: '/app/clientes',
    search: '',
    hash: '',
    key: 'qjrmh7',
    state: '',
  },
};

export default PrivateRoute;
