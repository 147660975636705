import React, { useState } from 'react';
import {
  StyledMainContent,
  StyledLabel,
  StyledInput,
  StyledInputContent,
  StyledButtonEye
} from './styles';
import eyeIcon from 'assets/icons/settings/eye.svg';
import PropTypes from 'prop-types';

function ModalInput({ label, value, onChange, placeholder, type, showEye }) {
  const [likePass, setLikePass] = useState(showEye);

  const handleClick = () => {
    setLikePass(!likePass);
  }

  return (
    <StyledMainContent>
      <StyledLabel>{label}</StyledLabel>
      <StyledInputContent>        
        <StyledInput type={likePass ? "password" : type} placeholder={placeholder} value={value} onChange={onChange} />
        {showEye && (
          <StyledButtonEye type="button" onClick={() => handleClick()}>
            <img src={eyeIcon} alt="icone de olho" />
          </StyledButtonEye>
        )}
      </StyledInputContent>
    </StyledMainContent>
  );
}

ModalInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  showEye: PropTypes.bool
}

ModalInput.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  placeholder: '',
  type: 'text',
  showEye: false
}

export default ModalInput;
