import styled from 'styled-components';

export const StyledMainContent = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: max-content;
`;
