import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  /* box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.12); */
  padding: 32px 36px;
  max-width: 400px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #00213F;
    margin-bottom: 16px;
  }

  h2 {
    margin-bottom: 80px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #1B407A;
  }
`;

export const StyledBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 8px;
  position: relative;
  border-bottom: 1px solid #BBC6D7;
`;

export const StyledBarEmpty = styled.div`
  height: 250px;
  display: flex;
  align-items: flex-end;
  :hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const StyledBar = styled.div`
  height: ${(props) => props.height};
  width: 36px;
  background-color: ${(props) => props.color};
  border-radius: 4px 4px 0px 0px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledValue = styled.span`
  position: absolute;
  bottom: 0;
  height: ${(props) => props.distance};
  margin-bottom: 30px;
`;