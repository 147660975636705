import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  height: 100%;
  text-align: center;
  padding: 10px 0;

  img {
    margin-left: 5px;
  }

  h1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #262626;
    margin-bottom: 35px;
  }

  h2 {
    color: #CE152F;
    font-size: 21px;
    margin-top: 10px;
  }
`;

export const StyledRedText = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #CE152F;
`;

export const StyledCommonText = styled.div`
  font-size: 15px;
  line-height: 21px;
  color: #8295B6;
  margin: 15px 0;
`;

export const StyledWithMargin = styled.div`
  margin: 0 25px;
`;
