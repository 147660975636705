import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: #FFFFFF;
  box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 50%;
  padding: 25px 33px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 11px;
  height: 170px;
`;

export const StyledIconContent = styled.div`
  background: ${(props) => props.background};
  width: 32px; 
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: 18px;
`;

export const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 12px;

  h3 {
    color: #727272;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 5px;
  }

  p {
    font-size: 18px;
    line-height: 27px;
    color: #262626;
    font-weight: 600;
  }
`;

export const StyledScore = styled.div`
  width: 100%;
  margin-left: 42px;

  p {
    font-weight: 600;
  }
`;

export const StyledScoreTitle = styled.h3`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.color};
`;
