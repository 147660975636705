import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  StyledMainContent,
  StyledInputDate,
  StyledDateContainer,
  StyledCalendarButton,
  StyledFilterHeader,
  StyledFilterContent,
  StyledOrangeTip,
  StyledSelectContent,
  StyledFooter,
  StyledButton,
  StyledUTMs,
  StyledSeeMore,
  StyledCloseButton
} from './styled';
import AutocompleteComponent from 'components/AutoComplete';
import Calendar from 'components/Calendar';
import calendarIcon from 'assets/icons/filter/calendar.svg';
import OrangeTip from 'assets/icons/report/orange-tip.svg';
import upIcon from 'assets/icons/filter/up.svg';
import closeIcon from 'assets/icons/filter/fechar.svg';
import downIcon from 'assets/icons/filter/down.svg';
import { StyledFlex } from 'utils/styles';
import { useOutsideClick } from 'utils/useOutsideClick';
import moment from 'moment';
import PropTypes from 'prop-types';

function FilterComponent({ visible, setVisible, handleSelectFilter, utms, onClick, selectionDate, setSelectionDate }) {
  const [initialDate, setInitialDate] = useState(moment(new Date(moment().subtract(10, 'days').calendar())).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [seeMore, setSeeMore] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (e) => {
    onClick();
    setVisible(false);
    e.preventDefault();
  }

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const isSameDate = () => {
    return formatDate(selectionDate?.startDate) === formatDate(selectionDate?.endDate);
  }

  useEffect(() => {
    if (moment(initialDate).isValid() && moment(endDate).isValid()) {
      const newRange = selectionDate;
      newRange.startDate = new Date(moment(initialDate).add('3', 'hours').format());
      newRange.endDate = new Date(moment(endDate).add('3', 'hours').format());
      setSelectionDate(newRange);
    }
  }, [initialDate, endDate]);

  useEffect(() => {
    if (moment(selectionDate?.startDate).isValid() && moment(selectionDate?.endDate).isValid()) {
      setInitialDate(moment(selectionDate?.startDate).format('YYYY-MM-DD'));
      setEndDate(moment(selectionDate?.endDate).format('YYYY-MM-DD'));
    }
  }, [selectionDate]);

  const handleClickOutside = () => {
    setShowCalendar(false);
  };

  const calendarRef = useOutsideClick(handleClickOutside);

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  }

  return (
    <StyledMainContent visible={visible} onSubmit={(e) => handleSubmit(e)} onClick={(e) => handleStopPropagation(e)}>
      <StyledUTMs>
        <StyledFlex flex="row" justify="space-between">
          <h1>Filtros</h1>
          <StyledCloseButton type="button" onClick={() => setVisible(false)}>
            <img src={closeIcon} alt="X" />
          </StyledCloseButton>
        </StyledFlex>
        <StyledDateContainer>
          <h2>Período</h2>
          <StyledFlex justify="space-between" flex="row">
            <StyledInputDate value={initialDate} onChange={(e) => setInitialDate(e.target.value)} type="date" />
            <p>a</p>
            <StyledInputDate value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" />
            <StyledCalendarButton ref={calendarRef} type="button" onClick={() => setShowCalendar(!showCalendar)}>
              <img src={calendarIcon} alt="icone de calendário" />
            </StyledCalendarButton>
            {showCalendar && <Calendar selectionDate={selectionDate} onChange={setSelectionDate} />}
          </StyledFlex>
        </StyledDateContainer>
        <StyledFilterHeader>
          <h2>UTM</h2>
        </StyledFilterHeader>
        <StyledFilterContent>
          {isSameDate() && <StyledOrangeTip><img src={OrangeTip} />Selecione o período desejado!</StyledOrangeTip>}
          <StyledSelectContent>
            <AutocompleteComponent label='Source' isDisabled={isSameDate()} options={utms?.source_utm || []} isMulti onChange={(value) => handleSelectFilter('utm_source', value)} />
          </StyledSelectContent>
          <StyledSelectContent>
            <AutocompleteComponent label='Medium' isDisabled={isSameDate()} options={utms?.medium_utm || []} isMulti onChange={(value) => handleSelectFilter('utm_medium', value)} />
          </StyledSelectContent>
          <StyledSelectContent>
            <AutocompleteComponent label='Campaign' isDisabled={isSameDate()} options={utms?.campaign_utm || []} isMulti onChange={(value) => handleSelectFilter('utm_campaign', value)} />
          </StyledSelectContent>
          <StyledSeeMore onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? 'Ver menos filtros' : 'Ver mais filtros'}
            <img src={seeMore ? upIcon : downIcon} />
          </StyledSeeMore>
          {seeMore && (
            <>
              <StyledSelectContent>
                <AutocompleteComponent label='Term' isDisabled={isSameDate()} options={utms?.term_utm || []} isMulti onChange={(value) => handleSelectFilter('utm_term', value)} />
              </StyledSelectContent>
              <StyledSelectContent>
                <AutocompleteComponent label="Content" isDisabled={isSameDate()} options={utms?.content_utm || []} isMulti onChange={(value) => handleSelectFilter('utm_content', value)} />
              </StyledSelectContent>
            </>
          )}
        </StyledFilterContent>
        {/* <h2>Ações</h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              name="checkbox"
              sx={{
                color: "#1B407A",
                '&.Mui-checked': {
                  color: "#1B407A",
                },
              }}
            />
          }
          label="Comparar UTMs"
        /> */}
      </StyledUTMs>
      <StyledFooter>
        <StyledButton type="button" className='no-background'>Limpar</StyledButton>
        <StyledButton type="submit">Filtrar</StyledButton>
      </StyledFooter>
    </StyledMainContent>
  );
}

FilterComponent.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  handleSelectFilter: PropTypes.func,
  onClick: PropTypes.func,
  selectionDate: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    key: PropTypes.string
  }),
  setSelectionDate: PropTypes.func,
  utms: PropTypes.shape({
    source_utm: PropTypes.arrayOf(PropTypes.string),
    medium_utm: PropTypes.arrayOf(PropTypes.string),
    campaign_utm: PropTypes.arrayOf(PropTypes.string),
    term_utm: PropTypes.arrayOf(PropTypes.string),
    content_utm: PropTypes.arrayOf(PropTypes.string),
  })
}

FilterComponent.defaultProps = {
  visible: false,
  setVisible: () => {},
  handleSelectFilter: () => { },
  onClick: () => { },
  setSelectionDate: () => { },
  selectionDate: {
    startDate: new Date(moment().subtract(10, 'days').calendar()),
    endDate: new Date(),
    key: 'selection',
  },
  utms: {
    source_utm: [],
    medium_utm: [],
    campaign_utm: [],
    term_utm: [],
    content_utm: []
  }
}

export default FilterComponent;
