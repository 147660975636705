import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PropTypes from 'prop-types';
import Cookies from 'js-cookie'

import { updateTerm } from "services/term";
import { getFacebookExpired } from "services/facebook";
import { getGoogleExpired } from "services/google";
import SideBar from "components/SideBar/SideBar";
import Button from 'components/Button';
import LoadingRadius from "components/LoadingRadius";
import { StyledModalContent, StyledModalTitle, StyledModalText, StyledBase, StyledMainCont, StyledBaseContainer } from './styles';
import { useAuth } from "contexts/auth";

function Base({ children }) {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const cookies = Cookies.get();
  const [expiredConnection, setExpiredConnection] = useState(false)
  const [connectorName, setConnectorName] = useState('');
  const { signOut } = useAuth();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleExpiredGoogleLogin = async () => {
    const googleResponse = await getGoogleExpired(cookies?.access_token);

    if (googleResponse?.data?.expired) {
      setConnectorName('Google');
      setExpiredConnection(
        googleResponse?.data?.expired,
      )
    }
  }

  const handleExpiredFaceLogin = async () => {
    const faceResponse = await getFacebookExpired();

    if (faceResponse?.data?.expired) {
      setConnectorName('Facebook');
      setExpiredConnection(
        faceResponse?.data?.expired,
      )
    }
  }

    useEffect(async () => {
      if (cookies?.access_token) {
        handleExpiredFaceLogin();
        handleExpiredGoogleLogin();
      }
    }, [cookies?.access_token]);

  const handleRedirect = () => {
    setExpiredConnection(false);
    history.push("/app/configuracoes");
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    await updateTerm({ "accepted_term": checked });
    setIsLoading(false);

    Cookies.set('accepted_term', checked, { path: '' })

    if (cookies.client_id) {
      location.reload();
    }
  }

  const isFalse = (value) => {
    return value === 'false' || value === 'null'
  }

  return (
    <StyledMainCont>
      <SideBar />
      {(isFalse(cookies.client_id)) && <Dialog open={(isFalse(cookies.client_id))}>
        <StyledModalContent>
          <StyledModalTitle>Associação necessária</StyledModalTitle>
          <StyledModalText>
            Para prosseguir utilizando o Sales Intelligence, você precisa está vinculado a algum de nossos clientes. Entre em contato com os administradores (equipe.digital@neurotech.com.br) para que possamos analisar e validar seu acesso.
          </StyledModalText>
          <FormGroup>
            <DialogActions>
              <Button type="button" className="button-intregation" onClick={() => signOut()}>{
                !isLoading ? 'Entendi' : (
                  <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#fff" />
                )}</Button>
            </DialogActions>
          </FormGroup>
        </StyledModalContent>
      </Dialog>}
      {isFalse(cookies.accepted_term) && <Dialog open={isFalse(cookies.accepted_term)}>
        <StyledModalContent>
          <StyledModalTitle>Ação necessária</StyledModalTitle>
          <StyledModalText>
            Para prosseguir utilizando o Sales Intelligence, você precisa ler com atenção e confirmar estar de acordo com os <a href="https://www.neurotech.com.br/termos-de-uso-do-sales-intelligence/" rel="noreferrer" target="_blank">Termos de uso</a>, <a href="https://www.neurotech.com.br/politica-de-privacidade-sales-intelligence/" rel="noreferrer" target="_blank">Política de privacidade</a> e <a href="https://www.neurotech.com.br/guia-orientativa-sales-intelligence" rel="noreferrer" target="_blank">Guia de Boas Práticas de Proteção de Dados - Adequação dos Produtos Digital</a>
          </StyledModalText>
          <FormGroup>
            <FormControlLabel control={<Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="Li e concordo com os termos estabelecidos acima" />
            <DialogActions>
              <Button className="button-intregation" id="accepted-terms" disabled={(!checked) || isLoading} onClick={() => handleSubmit()}>{
                !isLoading ? 'Confirmar' : (
                  <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#fff" />
                )}</Button>
            </DialogActions>
          </FormGroup>
        </StyledModalContent>
      </Dialog>}
      {<Dialog open={expiredConnection}>
        <StyledModalContent>
          <StyledModalTitle>Renovação de acesso</StyledModalTitle>
          <StyledModalText>
            {`Sua conexão com o conector ${connectorName} se expirou. Para continuar recebendo corretamente os dados da coleta, por favor, faça novamente o login no conector associado.`}
          </StyledModalText>
          <FormGroup>
            <DialogActions>
              <Button type="button" onClick={() => handleRedirect()} className="button-intregation">{
                !isLoading ? 'Entendi' : (
                  <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#fff" />
                )}</Button>
            </DialogActions>
          </FormGroup>
        </StyledModalContent>
      </Dialog>}
      <StyledBaseContainer>
        <StyledBase>
          {children}
        </StyledBase>
      </StyledBaseContainer>
    </StyledMainCont>
  );
}

Base.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Base;
