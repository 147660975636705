import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledMainContent,
  StyledButton
 } from './styled';

function Card({ children, onClick, title, icon, hasInstall }) {
  return (
    <StyledMainContent>
      <img src={icon} />
      <h2>{title}</h2>
      {children}
      {hasInstall && 
      ( <StyledButton onClick={onClick}>
          Instalar
        </StyledButton>
      )}
    </StyledMainContent>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
  hasInstall: PropTypes.bool
}

Card.defaultProps = {
  onClick: () => {},
  title: '',
  icon: '',
  hasInstall: true
}

export default Card;
