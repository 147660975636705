import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import {
  StyledMainContent,
  StyledTable,
  StyledTableHeader,
  StyledTableRow,
  StyledTableContent,
  StyledTag,
  StyledTagContainer,
  StyledLoadingContainer
} from './styles';
import LoadingRadius from "components/LoadingRadius";
import NotFoundComponent from "../CompareUTM/NotFoundResults";
import { getDashboardComparative } from 'services/dashboard';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

function TableContacts({ filtering, selectionDate, utmFiltered }) {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await getDashboardComparative(
        formatDate(selectionDate?.startDate),
        formatDate(selectionDate?.endDate),
        utmFiltered.utm_source,
        utmFiltered.utm_medium,
        utmFiltered.utm_campaign,
        utmFiltered.utm_term,
        utmFiltered.utm_content,
        page,
        10
      )
      setItems(response?.data?.items);
      setTotalPage(response?.data?.pages);
    } catch {
      setItems([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPage(1);
    setTotalPage(1);
    setItems([]);
    if (page === 1) {
      getData();
    }
  }, [filtering]);

  const convertFormat = (value = 0.00) => {
    value = parseFloat(value);
    if (value <= 0.04) {
      return `${(value * 1440).toFixed(1)} min`
    } else if (value > 0.04 && value < 1) {
      return `${((value * 1440) / 60).toFixed(1)} hora(s)`
    } else {
      return `${value.toFixed(1)} dia(s)`
    }
  }

  useEffect(() => {
    getData();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    isLoading && items.length === 0 ? (
      <StyledLoadingContainer>
        <LoadingRadius height="250px" />
      </StyledLoadingContainer>
    ) : (
      <>
        {items?.length > 0 ? (
          <StyledMainContent>
            <StyledTable>
              <StyledTableRow className='header'>
                <StyledTableHeader align="start" width='45%'>Nome da Campanha e UTMs</StyledTableHeader>
                <StyledTableHeader width='29%'>Volume de Leads</StyledTableHeader>
                {/* <StyledTableHeader width='22%'>Tempo para cadastro</StyledTableHeader> */}
                <StyledTableHeader width='26%'>Taxa de conversão</StyledTableHeader>
              </StyledTableRow>
              {items?.map((item, index) => (
                isLoading ? (
                  <StyledTableRow key={index}>
                    <StyledTableContent align="start" width='30%'>
                      <Skeleton />
                    </StyledTableContent>
                    <StyledTableContent width='15%'><Skeleton /></StyledTableContent>
                    <StyledTableContent width='27%'><Skeleton /></StyledTableContent>
                    {/* <StyledTableContent width='15%'><Skeleton /></StyledTableContent> */}
                    <StyledTableContent width='28%'><Skeleton /></StyledTableContent>
                  </StyledTableRow>
                ) : (
                  <StyledTableRow key={index}>
                    <StyledTableContent align="start" width='45%'>
                      {item?.name}
                      <StyledTagContainer>
                        {item?.utms?.source && <StyledTag>Source: {item?.utms?.source}</StyledTag>}
                        {item?.utms?.campaing && <StyledTag>Campaign: {item?.utms?.campaing}</StyledTag>}
                        {item?.utms?.medium && <StyledTag>Medium: {item?.utms?.medium}</StyledTag>}
                        {item?.utms?.term && <StyledTag>Term: {item?.utms?.term}</StyledTag>}
                        {item?.utms?.content && <StyledTag>Content: {item?.utms?.content}</StyledTag>}
                      </StyledTagContainer>
                    </StyledTableContent>
                    <StyledTableContent width='29%'>{item?.total?.toLocaleString('pt-BR')}</StyledTableContent>
                    {/* <StyledTableContent width='22%'>{convertFormat(item?.time_to_register ? item?.time_to_register : 0.00)}</StyledTableContent> */}
                    <StyledTableContent width='26%'>{item?.percentage?.toLocaleString('pt-BR')}%</StyledTableContent>
                  </StyledTableRow>
                )
              ))}
            </StyledTable>
            <Pagination count={totalPage} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
          </StyledMainContent>
        ) : (
          <NotFoundComponent />
        )}
      </>
    )
  );
}

TableContacts.propTypes = {
  filtering: PropTypes.bool,
  selectionDate: PropTypes.shape({
    key: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }),
  utmFiltered: PropTypes.shape({
    utm_source: PropTypes.string,
    utm_medium: PropTypes.string,
    utm_campaign: PropTypes.string,
    utm_term: PropTypes.string,
    utm_content: PropTypes.string,
  })
}

TableContacts.defaultProps = {
  filtering: false,
  selectionDate: {
    startDate: new Date(moment().subtract(10, 'days').calendar()),
    endDate: new Date(),
    key: 'selection',
  },
  utmFiltered: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: ''
  }
}

export default TableContacts;
