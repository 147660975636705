import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  padding: 35px;
`;

export const StyledTitle = styled.h1`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #262626;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const StyledModalContent = styled.div`
  padding: 32px;
  max-width: 380px;

  .button-intregation {
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form {
    margin-top: 15px;
  }
`;

export const StyledFeedbackContent = styled.div`
  padding: 32px;
  max-width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  p {
    margin: 10px 0;
  }
`;

export const StyledModalTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #00213F;
`;

export const StyledModalText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #667A8C;
`;

export const StyledSelectContainer = styled.div` 
  margin: 20px 0 40px 0;
  width: 70%;
`;
