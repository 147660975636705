import styled from 'styled-components';

export const StyledTableContent = styled.div`
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 25px;
  width: 100%;
`;

export const StyledMainContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;

  h2 {
    margin-left: 25px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #262626;
    margin-bottom: 10px;
  }
`;

export const StyledTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: 0px 10px 20px 10px;
`;

export const StyledSearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  .button-download {
    width: 145px;
  }

  .downloading {
    margin-right: 7px;
  }
`;
