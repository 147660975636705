import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import {
  StyledMainContent,
  StyledTable,
  StyledTableHeader,
  StyledTableRow,
  StyledTableContent,
  StyledTag,
  StyledLoadingContainer,
  StyledScoreTag,
  StyledTagScore,
  StyledScoresTagContent
} from './styles';
import LoadingRadius from "components/LoadingRadius";
import NotFoundComponent from "../../Contact/CompareUTM/NotFoundResults";
import { getRevenuebyUtm } from "services/utm";
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

function TableRevenue({ filtering, selectionDate, utmFiltered }) {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const colors = {
    "SUPER ALTO": '#22943E',
    "ALTO": '#8CC14C',
    "MEDIO": '#E7750A',
    "BAIXO": '#CE152F',
    "SC": '#C8C8C8'
  }

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await getRevenuebyUtm(
        formatDate(selectionDate?.startDate),
        formatDate(selectionDate?.endDate),
        utmFiltered.utm_source,
        utmFiltered.utm_medium,
        utmFiltered.utm_campaign,
        utmFiltered.utm_term,
        utmFiltered.utm_content,
        page,
        10
      )
      setItems(response?.data?.items);
      setTotalPage(response?.data?.pages);
    } catch {
      setItems([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPage(1);
    setTotalPage(1);
    setItems([]);
    if (page === 1) {
      getData();
    }
  }, [filtering]);

  useEffect(() => {
    getData();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getSumProperty = (scores, property) => {
    let sum = 0;
    scores?.map((i) => {
      if (i.score_desc === 'SUPER ALTO' || i.score_desc === 'ALTO') {
        sum += i[property]
      }
    })

    return sum;
  }

  const calculateTicket = (sumQL, sumHighScores) => {
    const ticket = +((sumQL)/(sumHighScores)).toFixed(2)
    return ticket || 0
  }

  return (
    isLoading && items.length === 0 ? (
      <StyledLoadingContainer>
        <LoadingRadius height="250px" />
      </StyledLoadingContainer>
    ) : (
      <>
        {items.length > 0 ? (
          <StyledMainContent>
            <StyledTable>
              <StyledTableRow className='header'>
                <StyledTableHeader align="start" width='35%'>Nome da Campanha e UTMs</StyledTableHeader>
                <StyledTableHeader width='15%'>Receita gerada</StyledTableHeader>
                <StyledTableHeader width='15%'>Custo por Lead Qualificado</StyledTableHeader>
                <StyledTableHeader width='20%'>Distribuição dos Scores</StyledTableHeader>
                <StyledTableHeader width='15%'>Ticket Médio dos Scores Alto e Super Alto</StyledTableHeader>
              </StyledTableRow>
              {items.map((item, index) => (
                isLoading ? (
                  <StyledTableRow key={index}>
                    <StyledTableContent align="start" width='35%'>
                      <Skeleton />
                    </StyledTableContent>
                    <StyledTableContent width='15%'><Skeleton /></StyledTableContent>
                    <StyledTableContent width='15%'><Skeleton /></StyledTableContent>
                    <StyledTableContent width='20%'><Skeleton /></StyledTableContent>
                    <StyledTableContent width='15%'><Skeleton /></StyledTableContent>
                  </StyledTableRow>
                ) : (
                  <StyledTableRow key={index}>
                    <StyledTableContent align="start" width='35%'>
                      {item?.name}
                      <StyledTagScore>
                        {item?.utms?.source && <StyledTag>Source: {item?.utms?.source}</StyledTag>}
                        {item?.utms?.campaing && <StyledTag>Campaign: {item?.utms?.campaing}</StyledTag>}
                        {item?.utms?.medium && <StyledTag>Medium: {item?.utms?.medium}</StyledTag>}
                        {item?.utms?.term && <StyledTag>Term: {item?.utms?.term}</StyledTag>}
                        {item?.utms?.content && <StyledTag>Content: {item?.utms?.content}</StyledTag>}
                      </StyledTagScore>
                    </StyledTableContent>
                    <StyledTableContent width='15%'>R$ {item?.total?.toLocaleString('pt-BR')}</StyledTableContent>
                    <StyledTableContent width='15%'>R$ {Number(item?.cplq?.toFixed(2))?.toLocaleString('pt-BR')}</StyledTableContent>
                    <StyledTableContent width='20%'>
                      <StyledScoresTagContent>
                        {item.scores.map((score) => (
                          <StyledScoreTag key={score?.score_desc} color={colors[score?.score_desc]}> {score?.score_desc}: {score?.sum_scoreds}</StyledScoreTag>
                        ))}
                      </StyledScoresTagContent>
                    </StyledTableContent>
                    <StyledTableContent width='15%'>{`R$ ${calculateTicket(getSumProperty(item?.scores, "value"), getSumProperty(item?.scores, "sum_scoreds")).toLocaleString('pt-BR')}`}</StyledTableContent>
                  </StyledTableRow>
                )
              ))}
            </StyledTable>
            <Pagination count={totalPage} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
          </StyledMainContent>
        ) : (
          <NotFoundComponent />
        )}
      </>
    )
  );
}

TableRevenue.propTypes = {
  filtering: PropTypes.bool,
  selectionDate: PropTypes.shape({
    key: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }),
  utmFiltered: PropTypes.shape({
    utm_source: PropTypes.string,
    utm_medium: PropTypes.string,
    utm_campaign: PropTypes.string,
    utm_term: PropTypes.string,
    utm_content: PropTypes.string,
  })
}

TableRevenue.defaultProps = {
  filtering: false,
  selectionDate: {
    startDate: new Date(moment().subtract(10, 'days').calendar()),
    endDate: new Date(),
    key: 'selection',
  },
  utmFiltered: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: ''
  }
}

export default TableRevenue;
