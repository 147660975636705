import React, { useState, useEffect } from 'react';
import {
  StyledTooltip
} from './styles';
import PropTypes from 'prop-types';

function Tooltip({ active, payload, label }) {
  const [sumValues, setSumValues] = useState(0);

  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < payload?.length; i++) {
      sum += payload[i]?.value;
      setSumValues(sum);
    }
  }, [payload]);

  const getValue = (key) => {
    const value = payload?.find((elem) => elem.dataKey === key)?.value;
    if (value) {
      return `${(+value.toFixed(1)).toLocaleString('pt-BR')} (${((value / sumValues) * 100).toFixed(1)}%)`;
    }
    return 0;
  }

  if (active) {
    return (
      <StyledTooltip>
        <h1>{label}</h1>
        <p>{`${getValue('sAlto')} Super Alto`}</p>
        <p>{`${getValue('alto')} Alto`}</p>
      </StyledTooltip>
    );
  }

  return null;
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
  label: PropTypes.string
}

Tooltip.defaultProps = {
  active: false,
  payload: {},
  labe: ''
}

export default Tooltip;
