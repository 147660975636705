import styled from 'styled-components';

export const StyledWrapper = styled.div`

  .css-y8ay40-MuiTableCell-root {
    color: #5B8DD7;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
  }

  .css-1ex1afd-MuiTableCell-root {
    font-size: 13px;
    color: #858585;

    &.color-baixo {
        color: #CA0009;
    }

    &.color-medio {
        color: #E7750A;
    }

    &.color-alto {
        color: #8CC14C;
    }

    &.color-super {
        color: #22943E;
    }
  }

  .css-u06erh-MuiPaper-root {
    box-shadow: none;
  }
`;
