import React from 'react';
import Button from 'components/Button';
import {
  StyledModalTitle,
  StyledFeedbackContent,
  StyledModalText
} from './styles';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import successIcon from 'assets/icons/alert/success.svg';
import failIcon from 'assets/icons/alert/fail.svg';

function GlobalAlert({ title, open, type, text, close }) {
  
  const handleClick = () => {
    close();
  }

  return (
    <Dialog open={open}>
      <StyledFeedbackContent>
        <img src={type === 'success' ? successIcon : failIcon} />
        <StyledModalTitle>{title}</StyledModalTitle>
          <StyledModalText>
            {text}
          </StyledModalText>
          <Button className="button-intregation" onClick={() => handleClick()}>
            Entendi
          </Button>
      </StyledFeedbackContent>
    </Dialog>
  )
}

GlobalAlert.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
  close: PropTypes.func,
}

GlobalAlert.defaultProps = {
  title: 'Alerta',
  open: false,
  type: 'success',
  text: '',
  close: () => {},
}

export default GlobalAlert;
