import React, { useState } from 'react';
import {
  StyledMainContent,
} from './styles';
import Navbar from 'components/Navbar';
import Contact from './Contact';
import UTM from './Revenue';
import PurchaseProfile from './PurchaseProfile';

function Reports() {
  const [currentPage, setCurrentPage] = useState('contact');

  const navOptions = [
    { id: 'contact', label: 'Contatos' },
    { id: 'receita', label: 'Receita' },
    // { id: 'perfil', label: 'Perfil de Consumo' }
  ];

  return (
    <StyledMainContent>
      <Navbar options={navOptions} onClick={(pageId) => setCurrentPage(pageId)} firstItem="contact" />
      {currentPage === 'contact' && (
        <Contact />
      )}
      {currentPage === 'receita' && (
        <UTM />
      )}
      {/* {currentPage === 'perfil' && (
        <PurchaseProfile />
      )} */}
    </StyledMainContent>
  );
}

export default Reports;
