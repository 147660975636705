import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 40px 0;
`;

export const StyledText = styled.div`
  max-width: 600px;
  margin-top: 100px;
  margin-right: -220px;
  z-index: 2;

  h2 {
    color: #E44040;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #00213F;
  }
`;