import styled from 'styled-components';

export const StyledBackground = styled.div`
  height: 100vh;
  padding: 50px 130px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  position: relative;
  background-color: #EDEFF4;
`;

export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 545px;
  width: 100%;

  img {
    width: 330px;
    margin-bottom: 35px;
  }

  .text {
    font-size: 20px;
    font-weight: 400;
    color: #8295B6;
  }
`;

export const StyledForms = styled.form`
  background-color: white;
  box-shadow: 0px 1px 14px 2px #0000001F;
  width: 100%;
  height: 100%;
  padding: 30px 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  position: relative;
  z-index: 2;
`;

export const StyledFormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 20%;
  margin-top: ${(props) => props.marginTop ? `${props.marginTop}` : '20%'};
`;

export const StyledFields = styled.div`
  width: 100%;
`;

export const StyledNoAccount = styled.p`
  color: #8295B6;
  font-size: 12px;
  margin-bottom: 7px;
  margin-top: 70px;
`;

export const StyledTitle = styled.h1`
  color: ${(props) => props.color ? `${props.color}` : '#1B407A'};
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  margin-bottom: ${(props) => props.margin ? `${props.margin}` : '20px'};
  font-size: ${(props) => props.fontSize ? `${props.fontSize}` : '20px'};
  font-family: 'Poppins';
  font-style: normal;

  &.no-margin {
    margin-bottom: 0px;
    font-size: 24px;
  }
`;

export const StyledButtonText = styled.p`
  width: 100%;
  font-weight: 500;
`;

export const StyledForgetPass = styled.p`
  color: #8295B6;
  font-size: 14px;
  text-align: center;
  width: 100%;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 14px;
  color: ${(props) => props.colorText};
  background-color: ${(props) => props.background};
  cursor: pointer;
  border: none;
  padding: 10px 24px;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 25px;

  :hover {
    opacity: 0.7;
  }

  :disabled {
    color: #667A8C;
    cursor: auto;
    background-color: #EDEFF4;

    :hover {
      opacity: 1;
    }
  }

`;

export const StyledPowered = styled.p`
  color: #8295B6;
  font-size: 12px;
  position: absolute;
  bottom: 50px;
`;

export const StyledIconTopLeft = styled.img`
  position: absolute;
  top: 40px;
  left: -160px;
`;

export const StyledIconTopRight = styled.img`
  position: absolute;
  top: -35px;
  right: -70px;
  width: 80px;
`;

export const StyledIconBotRight = styled.img`
  position: absolute;
  bottom: 80px;
  right: -90px;
  width: 130px;
`;

export const StyledIconBotLeft = styled.img`
  position: absolute;
  bottom: -150px;
  left: -210px;
  width: 300px;
`;

export const StyledInvisibleContainer = styled.div`
  width: 500px;
  max-height: 640px;
  min-height: 460px;
  height: 100%;
  margin: 30px;
  position: relative;
`;

export const StyledInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 35px;
  font-size: 14px;
  color: #8295B6;
  margin-bottom: ${(props) => props.marginBot ? `${props.marginBot}` : '35px'};

  .clickable {
    cursor: pointer;
    margin-left: 6px;
    color: #1B407A;
    text-decoration: underline;
    font-weight: 600;
  }
`;

export const StyledLabel = styled.label`
  margin-bottom: 15px;
  margin-top: ${(props) => props.marginTop || '0px'};
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #00213F;
  font-size: 14px;
`;

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledInput = styled.input`
  border: 1px solid #DBDDE6;
  color: #8295B6;
  padding: 11px 16px;
  border-radius: 5px;
  outline: none;

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #8295B6;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #8295B6;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #8295B6;
  }

  ::placeholder { /* Most modern browsers support this now. */
    color: #8295B6;
  }
`;

export const StyledAlert = styled.span`
  color: ${(props) => props.color ? props.color : '#8295B6'};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
  margin-left: ${(props) => props.marginLeft ? '30px' : 0};
  margin-bottom: ${(props) => props.marginBot ? '20px' : 0};
  img {
    margin-right: 5px;
  }
`;

export const StyledBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #8295B6;
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
`;

export const StyledCheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #8295B6;

  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0;
    margin-right: 6px;
  }

  a {
    text-decoration: none;
    color: #1B407A;
  }
`;

export const StyledMessage = styled.div`
  margin: 15px 0;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  max-height: 80px;
  background-color: ${(props) => props.background ? `${props.background}` : 'transparent'};
  color: ${(props) => props.color ? `${props.color}` : '#8295B6'};
  padding: 8px 15px;

  h2 {
    font-size: 14px;
    color: #22943E;
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
  }
`;

export const StyledFullWidth = styled.div`
  width: 100%;
`;

export const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    color: #8295B6;
    font-size: 14px;
  }
`;