import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import {
  StyledContent
} from './styles';
import PropTypes from 'prop-types';

function LoadingRadius({ className, height, lHeight, lWidth, color }) {
  return (
    <StyledContent className={className} height={height}>
      <TailSpin
        color={color}
        height={lHeight}
        width={lWidth}
      />
    </StyledContent>
  )
}

LoadingRadius.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  lHeight: PropTypes.number,
  lWidth: PropTypes.number,
  color: PropTypes.string
}

LoadingRadius.defaultProps = {
  className: 'loading',
  lHeight: 50,
  lWidth: 50,
  color: '#00213f'
}

export default LoadingRadius;
