import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SideBarData } from "./SideBarData";
import Cookies from 'js-cookie';
import SacoleIcon from 'assets/icons/navbar/sacola.svg';
import personIcon from '../../assets/icons/navbar/personas.svg';
import logoutIcon from '../../assets/icons/navbar/logout.svg';
import notificationIcon from '../../assets/icons/navbar/notification.svg';
import {
  StyledNavbar,
  StyledNavItems,
  StyledNavTitle,
  StyledLi,
  StyledBottomItems,
  StyledContainer,
  StyledText,
  StyledTitle,
  StyledInfos,
  StyledInfoName,
  StyledInfoEmail,
  StyledLogout
} from './styles';
import { useAuth } from "contexts/auth";

const SideBar = () => {
  const [active, setActive] = useState(window.location.pathname);
  const [mouseEnter, setMouseEnter] = useState(false);
  const cookies = Cookies.get();
  const { signOut } = useAuth();

  return (
    <>
      <StyledContainer>
        <StyledNavbar onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)} expandNav={mouseEnter}>
          <StyledNavItems>
            <StyledNavTitle>
              <Link to="/app">
                <img src={SacoleIcon} alt="Logo Sales intelligence" />
                {mouseEnter && <StyledTitle className={mouseEnter}>Sales Intelligence</StyledTitle>}
              </Link>
            </StyledNavTitle>
            {SideBarData.map((item, index) => {
              return (
                <StyledLi key={index} active={active === item.path}>
                  <Link onClick={() => setActive(item.path)} to={item.path}>
                    <img src={active === index ? item.activeIcon : item.icon} />
                    {mouseEnter && <StyledText className={mouseEnter}>{item.title}</StyledText>}
                  </Link>
                </StyledLi>
              );
            })}
            <StyledBottomItems>
              {/* <div className="no-redirect">
                <img src={notificationIcon} />
                {mouseEnter && <StyledText className={mouseEnter}>Notificações</StyledText>}
              </div> */}
              <div className="no-redirect">
                <img src={personIcon} />
                {mouseEnter && <StyledInfos>
                  {/* <StyledInfoName className={mouseEnter}>{cookies.fullName}</StyledInfoName> */}
                  <StyledInfoEmail className={mouseEnter}>{cookies.email}</StyledInfoEmail>
                </StyledInfos>}
              </div>
            </StyledBottomItems>
            <StyledLogout expandNav={mouseEnter} onClick={() => signOut()}>
              <img src={logoutIcon} alt="botão de logout" />
              {mouseEnter && <StyledText className={mouseEnter}>Logout</StyledText>}
            </StyledLogout>
          </StyledNavItems>
        </StyledNavbar>
      </StyledContainer>
    </>
  );
};

export default SideBar;
