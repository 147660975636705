import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import CompareIcon from 'assets/icons/menu/compare.svg';
import TableIcon from 'assets/icons/menu/table.svg';
import ChartIcon from 'assets/icons/menu/chart.svg';
import ArrowDownIcon from 'assets/icons/menu/arrow.svg';
import {
  StyledMainContainer
} from './styles';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: '#FFFFFF',
      },
    },
  },
}));

function CustomizedMenus({ handleChange, type }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    handleChange({ ...option });
  };

  return (
    <StyledMainContainer>
      <Button
        id="customized-button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={<img src={type.image} />}
        endIcon={<img src={ArrowDownIcon} />}
      >
        {type.text}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(type)}
      >
        <MenuItem onClick={() => handleClose({ text: 'Gráfico', image: ChartIcon })} disableRipple>
          <img style={{ marginRight: '10px' }} src={ChartIcon} />
          Gráfico
        </MenuItem>
        <MenuItem onClick={() => handleClose({ text: 'Comparativo', image: CompareIcon })} disableRipple>
          <img style={{ marginRight: '10px' }} src={CompareIcon} />
          Comparativo
        </MenuItem>
        <MenuItem onClick={() => handleClose({ text: 'Tabela', image: TableIcon })} disableRipple>
          <img style={{ marginRight: '10px' }} src={TableIcon} />
          Tabela
        </MenuItem>
      </StyledMenu>
    </StyledMainContainer>
  );
}

CustomizedMenus.propTypes = {
  type: PropTypes.shape({
    image: PropTypes.string,
    text: PropTypes.string
  }),
  handleChange: PropTypes.func
}

CustomizedMenus.defaultProps = {
  type: {
    image: '',
    text: ''
  },
  handleChange: () => {}
}

export default CustomizedMenus;