import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCard,
  StyledIconContent,
  StyledLabel
} from './styles';
import TooltipComponent from 'components/Tooltip';
import { StyledFlex } from 'utils/styles';
import Skeleton from '@mui/material/Skeleton';

function Card({ icon, label, value, background, tooltip, margin, isLoading }) {
  return (
    <StyledCard margin={margin}>
      <StyledLabel>
        <StyledFlex flex="row" align="flex-start" justify="space-between" >
          <StyledIconContent background={background}>
            <img src={icon} />
          </StyledIconContent>
          {tooltip && <TooltipComponent label={tooltip} position="top" />}
        </StyledFlex>
        <StyledFlex flex="column" align="flex-start" justify="flex-start">
          <h3>{label}</h3>
          {isLoading ? <p><Skeleton /></p> : <p>{value}</p>}
        </StyledFlex>
      </StyledLabel>
    </StyledCard>
  );
}

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  background: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  margin: PropTypes.string,
  isLoading: PropTypes.bool
}

Card.defaultProps = {
  value: 0,
  tooltip: '',
  margin: '11px',
  isLoading: false
}

export default Card;
