import React, { useEffect, useState } from 'react';
import { Checkbox } from "@mui/material";
import useQuery from './useQuery';
import {
  StyledBackground,
  StyledText,
  StyledForms,
  StyledTitle,
  StyledButtonText,
  StyledButton,
  StyledNoAccount,
  StyledPowered,
  StyledIconTopLeft,
  StyledIconTopRight,
  StyledIconBotRight,
  StyledIconBotLeft,
  StyledInvisibleContainer,
  StyledInline,
  StyledLabel,
  StyledInput,
  StyledBack,
  StyledFormContent,
  StyledFields,
  StyledForgetPass,
  StyledAlert,
  StyledCheckboxLabel,
  StyledMessage,
  StyledFooter,
  StyledFullWidth,
  StyledContainer
} from './styles';
import SalesIcon from 'assets/icons/login/sales.svg';
import DiamondIcon from 'assets/icons/login/diamond.svg';
import DiamondIcon2 from 'assets/icons/login/diamond2.svg';
import BackIcon from 'assets/icons/login/arrow_right.svg';
import WarningIcon from 'assets/icons/login/warning.svg';
import LoadingRadius from "components/LoadingRadius";
import { messages } from "utils/const"
import { createUser } from 'services/user';
import { postChangePassword, postRecuperacaoSenha } from 'services/recovery';
import { useGlobalAlert } from "contexts/alert";
import { useAuth } from 'contexts/auth';
import { getEmailVerification } from 'services/email_verification';

function LoginPage() {
  // 1-login, 2-cadastro, 3-esqueceu a senha, 4-reset senha
  const [phase, setPhase] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [sent, setSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [verification_code, setVerificationCode] = useState('');
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setAlert] = useGlobalAlert();
  const { signIn, loading } = useAuth();

  const clearStates = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  }

  useEffect(() => {
    clearStates();
  }, [phase]);

  let query = useQuery();

  const handleError = (error) => {
    setAlert({
      title: 'Wops, um erro ocorreu!',
      type: 'error',
      open: true,
      text: `${messages[error?.response?.data?.error] || error?.response?.statusText}`,
      confirmButton: () => { },
    })
  }

  useEffect(() => {
    const interval = setInterval(
      () => timer > 0 && setTimer(timer - 1),
      1000,
    );

    return () => clearInterval(interval);
  }, [timer]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (phase === 1) {
      signIn({ email, password });
    } else if (phase === 2) {
      if (passwordRequirements()) {
        try {
          const response = await createUser({
            "email": email,
            "password": password,
            confirmPassword,
            "terms": checked
          })

          if (response?.status === 201) {
            setAlert({
              title: 'Está quase lá!',
              type: 'success',
              open: true,
              text: 'Para finalizar o cadastro, verifique sua caixa de email para concluir o processo de verificação.',
              confirmButton: () => setPhase(1),
            })
          }
        } catch (error) {
          handleError(error);
        }
      }
    } else if (phase === 3) {
      try {
        setSent(false);
        const response = await postRecuperacaoSenha(email);
        setTimer(60)

        if (response?.status === 200) {
          setSent(true);
        }
      } catch (error) {
        handleError(error);
      }
    } else if (phase === 4) {
      try {
        const response = await postChangePassword(verification_code, password, confirmPassword);

        if (response?.status === 200) {
          setAlert({
            title: 'Sua senha foi modificada com sucesso!',
            type: 'success',
            open: true,
            text: 'Agora você pode logar no Sales Intelligence e utilizar todas nossas funcionalidades!',
            confirmButton: () => setPhase(1),
          })
        }
      } catch (error) {
        handleError(error);
      }
    }

    clearStates();
    setIsLoading(false);
  }

  const passwordRequirements = () => {
    return (password?.length >= 12 && password?.match(/([A-Z])+/g) && password?.match(/(\d)+/g) && password?.match(/[ !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g) && (password === confirmPassword));
  }

  useEffect(async () => {
    const code = query.get("verification_code")
    setVerificationCode(code);
    if (query.get("type") === "recovery") {
      setPhase(4);
    } else if (query.get("type") === "register") {
      try {
        setPhase(1);
        const response = await getEmailVerification(code)
        if (response?.status === 200) {
          setAlert({
            title: 'Sua conta foi verificada com sucesso!',
            type: 'success',
            open: true,
            text: 'Agora você pode logar no Sales Intelligence e utilizar todas nossas funcionalidades!',
            confirmButton: () => setPhase(1),
          })
        }

      } catch (error) {
        handleError(error);
      }
    }
  }, [query]);

  return (
    <StyledBackground>
      <StyledText>
        <img src={SalesIcon} />
        <p className='text'>
          Big Data e Machine Learning para você individualizar a experiência do consumidor em todas as etapas
          da jornada.
        </p>
      </StyledText>
      <StyledInvisibleContainer>
        <StyledForms onSubmit={(e) => onSubmit(e)}>
          {phase === 0 && <>
            <StyledTitle>
              Descubra o poder dos dados com o Sales Intelligence
            </StyledTitle>
            <StyledButton type='button' onClick={() => setPhase(1)} colorText='white' background='#1B407A'>
              <StyledButtonText>Entrar</StyledButtonText>
            </StyledButton>
            <StyledNoAccount>Não tem uma conta?</StyledNoAccount>
            <StyledButton type='button' onClick={() => setPhase(2)} colorText='#667A8C' background='#EDEFF4'>
              <StyledButtonText>Cadastre-se</StyledButtonText>
            </StyledButton>
            <StyledPowered>Powered by Neurotech</StyledPowered>
          </>}
          {phase === 1 &&
            <StyledFormContent>
              <StyledBack onClick={() => setPhase(phase - 1)}>
                <img src={BackIcon} alt='Icone para voltar' />
                <p>Voltar</p>
              </StyledBack>
              <StyledFields>
                <StyledTitle>
                  Faça seu login
                </StyledTitle>
                <StyledInline>
                  <p>Ainda não tem conta?</p>
                  <p onClick={() => setPhase(2)} className='clickable'> Faça seu cadastro</p>
                </StyledInline>
                <StyledLabel htmlFor='email'>
                  <p>E-mail*</p>
                  <StyledInput required type="email" id="email" placeholder='Insira o seu E-mail' onChange={(e) => setEmail(e.target.value)} />
                </StyledLabel>
                <StyledLabel htmlFor='pass'>
                  <p>Senha*</p>
                  <StyledInput required type="password" id="pass" placeholder='***********' onChange={(e) => setPassword(e.target.value)} />
                </StyledLabel>
                <StyledForgetPass onClick={() => setPhase(3)}>Esqueceu sua senha?</StyledForgetPass>
              </StyledFields>
              <StyledButton disabled={loading} type='submit' colorText='white' background='#1B407A'>
                {loading ? <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#1B407A" /> : <StyledButtonText>Entrar</StyledButtonText>}
              </StyledButton>
            </StyledFormContent>
          }
          {phase === 2 && (
            <StyledFormContent marginTop="50px">
              <StyledBack onClick={() => setPhase(0)}>
                <img src={BackIcon} alt='Icone para voltar' />
                <p>Voltar</p>
              </StyledBack>
              <StyledFields>
                <StyledTitle font="24px" margin="0px">
                  Realize seu cadastro
                </StyledTitle>
                <StyledInline marginBot="10px">
                  <p>Já tem conta?</p>
                  <p onClick={() => setPhase(1)} className='clickable'> Faça seu login</p>
                </StyledInline>
                <StyledLabel htmlFor='email'>
                  <p>E-mail*</p>
                  <StyledInput type="email" required id="email" placeholder='Insira o seu E-mail' onChange={(e) => setEmail(e.target.value)} />
                </StyledLabel>
                <StyledLabel htmlFor='pass'>
                  <p>Senha*</p>
                  <StyledInput type='password' required id="pass" placeholder='Insira a sua senha' onChange={(e) => setPassword(e.target.value)} />
                  <StyledAlert color="#8295B6"><img src={WarningIcon} />Sua senha deve conter:</StyledAlert>
                  <StyledAlert color={password.length >= 12 ? "#22943E" : "#E44040"} marginLeft>* 12 Caracteres</StyledAlert>
                  <StyledAlert color={password.match(/([A-Z])+/g) ? "#22943E" : "#E44040"} marginLeft>* 1 Letra maiúscula</StyledAlert>
                  <StyledAlert color={password.match(/(\d)+/g) ? "#22943E" : "#E44040"} marginLeft>* 1 Número</StyledAlert>
                  <StyledAlert color={password.match(/[ !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g) ? "#22943E" : "#E44040"} marginLeft>* 1 Caractere especial</StyledAlert>
                  <StyledAlert color={(password === confirmPassword) && password.length > 0 ? "#22943E" : "#E44040"} marginLeft>* As senhas devem coincidir </StyledAlert>
                </StyledLabel>
                <StyledLabel htmlFor='confirm-pass'>
                  <p>Confirmar senha*</p>
                  <StyledInput type='password' required id="confirmPass" placeholder='Insira sua senha novamente' onChange={(e) => setConfirmPassword(e.target.value)} />
                </StyledLabel>
                <StyledCheckboxLabel>
                  <Checkbox typeof='button' checked={checked} onChange={() => setChecked(!checked)} required id="terms" />
                  <label htmlFor='terms'>Aceito os <a rel="noreferrer" target="_blank" href='https://www.neurotech.com.br/termos-de-uso-do-sales-intelligence/'>Termos de Uso</a> e <a rel="noreferrer" target="_blank" href='https://www.neurotech.com.br/politica-de-privacidade-sales-intelligence/'>Políticas de Privacidade</a></label>
                </StyledCheckboxLabel>
              </StyledFields>
              <StyledButton type='submit' disabled={isLoading} colorText='white' background='#1B407A'>
                {isLoading ?
                  <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#1B407A" /> :
                  <StyledButtonText>Criar Conta</StyledButtonText>
                }
              </StyledButton>
            </StyledFormContent>
          )}
          {phase === 3 && (
            <StyledFormContent>
              <StyledContainer>
                <StyledBack onClick={() => setPhase(1)}>
                  <img src={BackIcon} alt='Icone para voltar' />
                  <p>Voltar</p>
                </StyledBack>
                <StyledTitle color="#E7750A" font="24px" margin="0px">
                  Restaurar senha
                </StyledTitle>
                <StyledMessage background={sent ? '#D2F6D9' : ''} color={sent ? '#2A2A2A' : '#8295B6'}>
                  {sent && <h2>E-mail enviado!</h2>}
                  <p>{sent ?
                    'Em breve você receberá um e-mail com um link de redirecionamento para alteração da senha.' :
                    'Insira abaixo o e-mail cadastrado para receber o link de recuparação de senha'}</p>
                </StyledMessage>
                <StyledLabel marginTop="10px" htmlFor='email'>
                  <p>E-mail*</p>
                  <StyledInput value={email} type="email" required id="email" placeholder='Insira o seu E-mail' onChange={(e) => setEmail(e.target.value)} />
                </StyledLabel>
              </StyledContainer>
              <StyledFooter>
                {sent && (
                  <>
                    <span>Não recebeu?</span>
                    <span>Verifique sua caixa de spam</span>
                  </>
                )}
                <StyledButton type='submit' disabled={(!(timer === 0) && sent) || isLoading} colorText='white' background='#1B407A'>
                  {isLoading ?
                    <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#1B407A" /> :
                    <StyledButtonText>{sent ? `Reenviar ${timer > 0 ? `em ${timer}s` : ''}` : 'Enviar'}</StyledButtonText>
                  }
                </StyledButton>
              </StyledFooter>
            </StyledFormContent>
          )}
          {phase === 4 && (
            <StyledFormContent>
              <StyledBack onClick={() => setPhase(0)}>
                <img src={BackIcon} alt='Icone para voltar' />
                <p>Voltar</p>
              </StyledBack>
              <StyledFullWidth>
                <StyledTitle color="#E7750A" font="24px" margin="30px">
                  Redifinir senha
                </StyledTitle>
                <StyledLabel htmlFor='pass'>
                  <StyledAlert color="#8295B6">Sua nova senha precisa conter no mínimo:</StyledAlert>
                  <StyledAlert color={password.length >= 12 ? "#22943E" : "#E44040"} marginLeft>* 12 Caracteres</StyledAlert>
                  <StyledAlert color={password.match(/([A-Z])+/g) ? "#22943E" : "#E44040"} marginLeft>* 1 Letra maiúscula</StyledAlert>
                  <StyledAlert color={password.match(/(\d)+/g) ? "#22943E" : "#E44040"} marginLeft>* 1 Número</StyledAlert>
                  <StyledAlert color={password.match(/[ !\"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g) ? "#22943E" : "#E44040"} marginLeft>* 1 Caractere especial</StyledAlert>
                  <StyledAlert color={(password === confirmPassword) && password.length > 0 ? "#22943E" : "#E44040"} marginLeft marginBot>* As senhas devem coincidir </StyledAlert>
                  <p>Nova senha*</p>
                  <StyledInput type='password' required id="pass" placeholder='Insira a sua senha' onChange={(e) => setPassword(e.target.value)} />
                </StyledLabel>
                <StyledLabel htmlFor='confirm-pass'>
                  <p>Confirmar senha*</p>
                  <StyledInput type='password' value={confirmPassword} required id="confirmPass" placeholder='Insira sua senha novamente' onChange={(e) => setConfirmPassword(e.target.value)} />
                </StyledLabel>
              </StyledFullWidth>
              <StyledFooter>
                <StyledButton type='submit' disabled={isLoading} colorText='white' background='#1B407A'>
                  {isLoading ?
                    <LoadingRadius height="17px" lHeight={17} lWidth={17} color="#1B407A" /> :
                    <StyledButtonText>Salvar nova senha</StyledButtonText>
                  }
                </StyledButton>
              </StyledFooter>
            </StyledFormContent>
          )}
        </StyledForms>
        <StyledIconTopLeft src={DiamondIcon} />
        <StyledIconTopRight src={DiamondIcon2} />
        <StyledIconBotRight src={DiamondIcon2} />
        <StyledIconBotLeft src={DiamondIcon} />
      </StyledInvisibleContainer>
    </StyledBackground>
  );
}

export default LoginPage;
