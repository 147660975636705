import { config, dashApi } from 'services/api';

export async function getDashboard(start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont) {
  return dashApi.get('dashboard/', config({ start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont }));
}

export async function getDashboardComparative(start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont,page, size) {
    return dashApi.get('dashboard/compare/', config({ start_date, end_date, nl_src, nl_mdm, nl_cmpg, nl_term, nl_cont, page, size }));
  }

export async function getUtms(start_date, end_date) {
  return dashApi.get('utm/', config({ start_date, end_date }));
}
