import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';

function SelectComponent({ options, label, state, onChange, valueKey, labelKey }) {

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="input-label-select">{label}</InputLabel>
      <Select
        labelId="select"
        id="select-id"
        value={state}
        label={label}
        autoWidth
        onChange={handleChange}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item[valueKey]}>{item[labelKey]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  valueKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  labelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  state: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func
}

SelectComponent.defaultProps = {
  options: [],
  valueKey: 'value',
  labelKey: 'label',
  label: 'Selecione uma opção',
  state: '',
  onChange: () => {}
}

export default SelectComponent;
