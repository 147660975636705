import React, { useEffect, useState } from 'react';
import {
  StyledMainContent,
  StyledDash,
  StyledCards,
  StyledChart,
  StyledLegend,
  StyledLegendItem,
  StyledLegendColor,
  StyledChartHeader,
  StyledDashFilter,
  StyledAsideCards,
  StyledHeadCards,
  StyledLineChart,
  StyledChartContainer,
  StyledChatDiv,
  StyledButton,
  StyledHeader
} from './styles';
import moment from 'moment';
import { getDashboard, getUtms } from "services/dashboard";
import { parseData } from "./parserData";
import downArrowIcon from 'assets/icons/report/icon-arrow-down.svg';
import arrowIcon from 'assets/icons/report/icon-arrow.svg';
import contactIcon from 'assets/icons/report/contact.svg';
import timeIcon from 'assets/icons/report/time.svg';
import adIcon from 'assets/icons/report/ad.svg';
import Card from './Card';
import ContactCard from './ContactCard'
import MixedChart from 'components/MixedChart';
import LineChartComp from 'components/LineChart';
import Filter from 'components/Filter';
import NotFoundComponent from './NotFoundChart';
import LoadingRadius from "components/LoadingRadius";
import Menu from "./Menu";
import CompareUTM from "./CompareUTM";
import TableUTM from './TableContacts';
import FilterIcon from 'assets/icons/report/filter.svg';
import ChartIcon from 'assets/icons/menu/chart.svg';
import { StyledFlex } from 'utils/styles';
import { useOutsideClick } from 'utils/useOutsideClick';

function Contact() {
  const [selectionDate, setSelectionDate] = useState(
    {
      startDate: new Date(moment().subtract(10, 'days').calendar()),
      endDate: new Date(),
      key: 'selection',
    }
  );
  const [isLoading, setIsLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [reports, setReports] = useState({ data: [] });
  const [utms, setUtms] = useState({});
  const [utmFiltered, setUtmFiltered] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [type, setType] = useState({
    text: 'Gráfico', image: ChartIcon
  });

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const isSameDate = () => {
    return formatDate(selectionDate?.startDate) === formatDate(selectionDate?.endDate);
  }

  useEffect(() => {

    if (!isSameDate()) {
      getUtm();
    }
  }, [selectionDate]);

  const getUtm = async () => {
    try {
      const response = await getUtms(formatDate(selectionDate?.startDate), formatDate(selectionDate?.endDate));
      setUtms(response?.data);
    } catch {
      setUtms({});
    }
  }

  useEffect(() => {
    getData();
  }, [selectionDate]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const response = await getDashboard(
        formatDate(selectionDate?.startDate),
        formatDate(selectionDate?.endDate),
        utmFiltered.utm_source,
        utmFiltered.utm_medium,
        utmFiltered.utm_campaign,
        utmFiltered.utm_term,
        utmFiltered.utm_content,
      )
      setReports(response?.data);
    } catch {
      setReports({ data: [] });
    }
    setIsLoading(false);
  };

  const handleFilter = () => {
    setFiltering(!filtering);
    setReports({ data: [] });
    if (type.text === 'Gráfico') {
      getData();
    }
  }

  const handleSelectFilter = (params, value) => {
    const oldValue = {
      ...utmFiltered
    };
    const elem = value.map((elem) => {
      return elem;
    });

    oldValue[params] = elem;

    setUtmFiltered({ ...oldValue })
  }

  const ScoreData = parseData(reports?.data);

  const RenderScoredsChart = () => (
    reports?.data?.length > 0 ? (
      <StyledChatDiv>
        <MixedChart data={ScoreData} />
        <StyledChartHeader>
          <StyledFlex>
            <StyledLegend>
              <StyledLegendItem>
                <StyledLegendColor color="#22943E" />
                Super Alto
              </StyledLegendItem>
              <StyledLegendItem>
                <StyledLegendColor color="#8CC14C" />
                Alto
              </StyledLegendItem>
              <StyledLegendItem>
                <StyledLegendColor color="#E7750A" />
                Médio
              </StyledLegendItem>
              <StyledLegendItem>
                <StyledLegendColor color="#CA0009" />
                Baixo
              </StyledLegendItem>
              <StyledLegendItem>
                <StyledLegendColor color="#C8C8C8" />
                Sem classificação
              </StyledLegendItem>
            </StyledLegend>
          </StyledFlex>
        </StyledChartHeader>
      </StyledChatDiv>
    ) : (
      <NotFoundComponent />
    )
  )

  const RenderVariationChart = () => (
    ScoreData.length > 0 ? (
      <LineChartComp data={ScoreData} />
    ) : (
      <NotFoundComponent />
    )
  )

  const handleClickOutside = () => {
    setOpenFilter(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <StyledMainContent>
      <StyledDashFilter>
        <StyledDash>
          <StyledHeader>
            <Menu handleChange={setType} type={type} />
            <StyledButton type="button" ref={ref} onClick={() => setOpenFilter(!openFilter)}>
              <img src={FilterIcon} />
              <p>Filtrar</p>
            </StyledButton>
          </StyledHeader>
          {type.text === 'Gráfico' && (
            <>
              <StyledCards>
                <ContactCard
                  label="Total de contatos scorados"
                  value={reports?.total?.toLocaleString('pt-BR')}
                  icon={contactIcon}
                  scores={reports?.scores}
                  background='rgb(130, 149, 182, 0.3)'
                  tooltip="Quantidade total de pessoas scoradas no período selecionado." />
                <StyledHeadCards>
                  <Card
                    label="Relação com mês anterior"
                    value={reports?.last_month === 'NAN' ? '-' : reports?.last_month}
                    icon={reports?.last_month?.includes('-') ? downArrowIcon : arrowIcon}
                    background={reports?.last_month?.includes('-') ? 'rgba(228,64,64,0.3)' : 'rgb(140, 193, 76, 0.3)'}
                    tooltip="Percentual comparando a quantidade total de scores Alto e Super Alto, nas datas e filtros selecionadas, em relação ao mesmo período no mês anterior." />
                  <Card
                    label="Relação com ano anterior"
                    value={reports?.last_year === 'NAN' ? '-' : reports?.last_year}
                    icon={reports?.last_year?.includes('-') ? downArrowIcon : arrowIcon}
                    background={reports?.last_year?.includes('-') ? 'rgba(228,64,64,0.3)' : 'rgb(140, 193, 76, 0.3)'}
                    tooltip="Percentual comparando a quantidade total de scores Alto e Super Alto, nas datas e filtros selecionadas, em relação ao mesmo período no ano anterior." />
                </StyledHeadCards>
              </StyledCards>
              <StyledLineChart>
                {isLoading ? (
                  <LoadingRadius height="250px" />
                ) : (
                  <>
                    <h3 className='overview'>Variação dos scores Alto e Super Alto</h3>
                    {RenderVariationChart()}
                  </>
                )}
              </StyledLineChart>
              <StyledCards>
                <StyledChart>
                  {isLoading ? (
                    <LoadingRadius height="250px" />
                  ) : (
                    <StyledChartContainer>
                      <h3 className='overview'>Quantidade Scorados</h3>
                      {RenderScoredsChart()}
                    </StyledChartContainer>
                  )}
                </StyledChart>
                {/* <StyledAsideCards>
                  <Card
                    label="Tempo médio para cadastro"
                    value={"-"}
                    icon={timeIcon}
                    background="rgba(130,149,182,0.3)"
                    tooltip="Refere-se ao intervalo médio de dias entre o contato com a campanha até o cadastro ser realizado." />
                  <Card
                    label="Quant. média de anúncios vistos até o cadastro"
                    value={"-"}
                    icon={adIcon}
                    background={'rgba(130,149,182,0.3)'}
                    tooltip="Exibe a quantidade média de anúncios/campanhas que o cliente interage até realizar o cadastro. " />
                </StyledAsideCards> */}
              </StyledCards>
            </>
          )}
          {type.text === 'Comparativo' && <CompareUTM filtering={filtering} utmFiltered={{ ...utmFiltered }} selectionDate={{ ...selectionDate }} />}
          {type.text === 'Tabela' && <TableUTM filtering={filtering} utmFiltered={{ ...utmFiltered }} selectionDate={{ ...selectionDate }} />}
        </StyledDash>
        <Filter
          visible={openFilter}
          setVisible={setOpenFilter}
          utms={utms}
          handleSelectFilter={handleSelectFilter}
          onClick={() => handleFilter()}
          selectionDate={selectionDate}
          setSelectionDate={setSelectionDate} />
      </StyledDashFilter>
    </StyledMainContent>
  );
}

export default Contact;
