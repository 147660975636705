import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledCard,
  StyledIconContent,
  StyledLabel,
  StyledScore,
  StyledScoreTitle
} from './styles';
import TooltipComponent from 'components/Tooltip';
import { StyledFlex } from 'utils/styles';

function ContactCard({ icon, label, value, scores, background, tooltip }) {
  return (
    <StyledCard>
      <StyledLabel>
        <StyledIconContent background={background}>
          <img src={icon} />
        </StyledIconContent>
        <StyledFlex flex="column" align="flex-start" justify="flex-start">
          <h3>{label}</h3>
          <p>{value}</p>
        </StyledFlex>
        <TooltipComponent label={tooltip} position="top" />
      </StyledLabel>
      <StyledFlex flex="row" align="flex-start" justify="space-between">
        <StyledScore>
          <StyledScoreTitle color="#22943E">Score Alto e Super Alto</StyledScoreTitle>
          <p>{scores?.total_auto_sAlto}</p>
        </StyledScore>
        <StyledScore>
          <StyledScoreTitle color="#E7750A">Score Médio</StyledScoreTitle>
          <p>{scores?.total_medio}</p>
        </StyledScore>
        <StyledScore>
          <StyledScoreTitle color="#CE152F">Score Baixo</StyledScoreTitle>
          <p>{scores?.total_baixo}</p>
        </StyledScore>
      </StyledFlex>
    </StyledCard>
  );
}

ContactCard.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  scores: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  background: PropTypes.string.isRequired,
  tooltip: PropTypes.string
}

ContactCard.defaultProps = {
  value: 0,
  tooltip: 'Descrição',
  scores: {
    total_auto_sAlto: "-",
    total_medio: "-",
    total_baixo: "-",
  }
}

export default ContactCard;
